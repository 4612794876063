import React, { useState } from "react";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAllModalStack,
  setCurrentModal,
} from "../../../../Redux/Slices/navSlice";
import SearchInputDropdown from "../../../../components/Inputs/SearchInputDropdown";
import { mapToLabelValueArray } from "../../../../helpers";
import CustomModalStack from "../../../../components/Modals/CustomModalStack";
import { addInvoice } from "../../../../Apis/Inventory";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
`;

const AddNewInvoiceModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    ref: "",
    amount: "",
    invoiceDate: "",
    supplierId: "",
  });
  const [addingInvoice, setAddingInvoice] = useState(false);

  const { suppliers } = useSelector((state) => state.supplier);

  const handleCreate = async () => {
    setAddingInvoice(true);
    try {
      await addInvoice(formData);
      handleClose();
    } catch (error) {
      console.error("Error adding invoice:", error);
    } finally {
      setAddingInvoice(false);
    }
  };

  const handleClose = () => {
    setFormData({
      ref: "",
      amount: "",
      invoiceDate: "",
      supplierId: "",
    });
    dispatch(closeAllModalStack());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelect = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <CustomModalStack
      id="add_invoice"
      content={
        <Container>
          <ModalTitle>Add New Invoice</ModalTitle>
          <FlexContainer>
            <TextInput
              label={"Reference"}
              placeholder={"Enter Invoice Number"}
              value={formData.ref}
              handleChange={handleChange}
              name="ref"
            />
            <TextInput
              label={"Total Amount (₦)"}
              placeholder={"Price"}
              value={formData.amount}
              handleChange={handleChange}
              name="amount"
              type={"number"}
            />
          </FlexContainer>
          <FlexContainer>
            <TextInput
              label={"Invoice Date"}
              placeholder={"Date"}
              value={formData.invoiceDate}
              handleChange={handleChange}
              name="invoiceDate"
              type={"date"}
            />
          </FlexContainer>
          <FlexContainer style={{ alignItems: "last baseline" }}>
            <SearchInputDropdown
              placeholder={"Search Supplier"}
              label={"Supplier"}
              value={formData.supplierId}
              handleSelect={(name, value) => handleSelect(name, value)}
              name="supplierId"
              items={mapToLabelValueArray(suppliers?.items, "name", "id")}
            />
            <FilledButton
              bgColor={theme.blue}
              btnText={"Add Supplier"}
              handleClick={() => dispatch(setCurrentModal("add_new_supplier"))}
              textColor={theme.white}
            />
          </FlexContainer>
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={handleClose}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleCreate}
              textColor={theme.white}
              disabled={
                !formData.ref ||
                !formData.supplierId ||
                !formData.amount ||
                !formData.invoiceDate ||
                addingInvoice
              }
              loading={addingInvoice}
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default AddNewInvoiceModal;
