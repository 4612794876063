import React from "react";
import styled, { useTheme } from "styled-components";

import { useSelector } from "react-redux";
import { selectStock } from "../../../../Redux/Slices/StockSlice";
import {
  BiBarcode,
  BiCalendar,
  BiInfoCircle,
  BiPackage,
  BiTrendingDown,
  BiUnite,
} from "react-icons/bi";
import FilledButton from "../../../../components/Buttons/FilledButton";
import moment from "moment";
import { CgProductHunt } from "react-icons/cg";
import { GiBrandyBottle, GiPriceTag } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { PiPower } from "react-icons/pi";
import { BsPerson } from "react-icons/bs";
import { SiNamebase } from "react-icons/si";
import { FaShapes } from "react-icons/fa";

// Styled Components

const Card = styled.div`
  max-width: 800px;
  width: 100%;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  align-self: center;
  justify-self: center;
`;

const Header = styled.div`
  background: ${({ theme }) => theme.blue};
  color: white;
  padding: 20px;
  text-align: center;

  h1 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  p {
    margin-top: 4px;
    font-size: 0.9rem;
    opacity: 0.8;
  }
`;

const Content = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    color: ${({ theme }) => theme.blue};
    flex-shrink: 0;
  }

  p {
    margin: 0;
    color: #374151;
    font-size: 0.95rem;

    span {
      font-weight: 500;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const Footer = styled.div`
  padding: 20px;
  background: #f3f4f6;
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  button {
    padding: 10px 16px;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s ease;

    &.back {
      background: #e5e7eb;
      color: #374151;

      &:hover {
        background: #d1d5db;
      }
    }

    &.edit {
      background: #3b82f6;
      color: white;

      &:hover {
        background: #2563eb;
      }
    }
  }
`;

// Main Component
// Main Component
const StockDetailsPage = () => {
  const { selectedStock } = useSelector(selectStock);
  const theme = useTheme();
  const navigate = useNavigate();

  console.log(selectedStock);

  return (
    <Card>
      {/* Header */}
      <Header>
        <h1>{selectedStock?.product?.name}</h1>
        {/* <p>{selectedStock?.category}</p> */}
      </Header>

      {/* Content */}
      <Content>
        {/* Stock Details */}
        <Details>
          <DetailItem>
            <CgProductHunt />
            <p>
              <span>Name:</span> {selectedStock?.product?.name}
            </p>
          </DetailItem>
          <DetailItem>
            <BiInfoCircle />
            <p>
              <span>Description:</span> {selectedStock?.product?.description}
            </p>
          </DetailItem>
          <DetailItem>
            <BiPackage />
            <p>
              <span>Quantity:</span> {selectedStock?.quantity}
            </p>
          </DetailItem>
          <DetailItem>
            <GiPriceTag />
            <p>
              <span>Price: </span>
              {selectedStock?.currency?.symbol} {selectedStock?.price}
            </p>
          </DetailItem>
          <DetailItem>
            <BiTrendingDown />
            <p>
              <span>Low Stock Threshold:</span> {selectedStock?.stockLowLevel}
            </p>
          </DetailItem>
          <DetailItem>
            <BiCalendar />
            <p>
              <span>Last Updated:</span>{" "}
              {moment(selectedStock?.updatedAt).format("Do MMM YYYY")}
            </p>
          </DetailItem>

          {/* Additional Product Details */}
          <DetailItem>
            <PiPower />
            <p>
              <span>Strength:</span> {selectedStock?.product?.strength}
            </p>
          </DetailItem>
          <DetailItem>
            <BiUnite />
            <p>
              <span>Unit Size:</span> {selectedStock?.product?.unitSize}
            </p>
          </DetailItem>
          <DetailItem>
            <BsPerson />
            <p>
              <span>Manufacturer:</span> {selectedStock?.product?.manufacturer}
            </p>
          </DetailItem>
          <DetailItem>
            <FaShapes />
            <p>
              <span>Form:</span> {selectedStock?.product?.form}
            </p>
          </DetailItem>
          <DetailItem>
            <SiNamebase />
            <p>
              <span>Generic Name:</span> {selectedStock?.product?.genericName}
            </p>
          </DetailItem>
          <DetailItem>
            <BiBarcode />
            <p>
              <span>Barcode:</span> {selectedStock?.product?.barcode}
            </p>
          </DetailItem>
          <DetailItem>
            <GiBrandyBottle />
            <p>
              <span>Brand Name:</span> {selectedStock?.product?.brandName}
            </p>
          </DetailItem>
        </Details>

        {/* selectedStock Image */}
        <ImageContainer>
          <img
            src={selectedStock?.product?.imageUrl}
            alt={selectedStock?.product?.name}
          />
        </ImageContainer>
      </Content>

      {/* Footer */}
      <Footer>
        <FilledButton
          bgColor={theme.blue}
          btnText={"Back"}
          handleClick={() => navigate("/stock-management")}
        />
      </Footer>
    </Card>
  );
};

export default StockDetailsPage;
