import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import SelectOptions from "../../../../components/Inputs/SelectOptions";
import {
  getStockStateUpdate,
  selectStock,
} from "../../../../Redux/Slices/StockSlice";
import { selectCategory } from "../../../../Redux/Slices/CategorySlice";
import { selectSupplier } from "../../../../Redux/Slices/SupplierSlice";
import { formatDate, mapToLabelValueArray } from "../../../../helpers";
import TextArea from "../../../../components/Inputs/TextArea";
import { selectCurrency } from "../../../../Redux/Slices/currencySlice";
import FIleInput from "../../../../components/Inputs/FileInput";
import { editStock } from "../../../../Apis/StockManagement";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const EditStockModal = () => {
  const theme = useTheme();
  const {
    batch,
    costPrice,
    sellingPrice,
    qty,
    supplier,
    category,
    mtfDate,
    expDate,
    addingStock,
    name,
    description,
    currencyId,
    imageUrl,
  } = useSelector(selectStock);
  const dispatch = useDispatch();
  const { categories } = useSelector(selectCategory);
  const { suppliers } = useSelector(selectSupplier);
  const { currencies } = useSelector(selectCurrency);
  const [categoryItems, setCategoryItems] = useState();
  const [supplierItems, setSupplierItems] = useState();
  const [currencyItems, setCurrencyItems] = useState();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getStockStateUpdate({ name, value }));
  };

  const handleImageSelection = (e) => {
    const file = e.target.files[0];
    dispatch(getStockStateUpdate({ name: e.target.name, value: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    editStock({
      batch,
      costPrice,
      sellingPrice,
      quantity: qty,
      supplierId: supplier,
      categoryId: category,
      dateOfManufacture: mtfDate,
      dateOfExpiration: expDate,
      name,
      description,
      currencyId,
      imageUrl,
    });
  };

  useEffect(() => {
    setCategoryItems(mapToLabelValueArray(categories?.items, "name", "id"));
    setSupplierItems(mapToLabelValueArray(suppliers?.items, "name", "id"));
    setCurrencyItems(mapToLabelValueArray(currencies?.items, "name", "id"));
  }, [categories, suppliers, currencies]);

  return (
    <CustomModal
      id="edit_Stock"
      content={
        <Container>
          <ModalTitle>Add Stock</ModalTitle>
          <FlexContainer>
            <TextInput
              label={"Stock Name"}
              placeholder={"Enter Stock Name"}
              name={"name"}
              value={name}
              handleChange={handleChange}
            />
            <TextInput
              label={"Stock Batch"}
              placeholder={"Stock batch"}
              name={"batch"}
              handleChange={handleChange}
              value={batch}
            />
          </FlexContainer>
          <TextArea
            label={"Stock Description"}
            placeholder={"Enter Stock Description"}
            name={"description"}
            value={description}
            handleChange={handleChange}
          />

          <FlexContainer>
            <TextInput
              label={"Cost price"}
              placeholder={"Cost Price"}
              name={"costPrice"}
              handleChange={handleChange}
              value={costPrice}
            />
            <TextInput
              label={"Selling Price"}
              placeholder={"Selling Price"}
              name={"sellingPrice"}
              handleChange={handleChange}
              value={sellingPrice}
            />
            <TextInput
              label={"QTY/Carton"}
              placeholder={"Price"}
              name={"qty"}
              handleChange={handleChange}
              value={qty}
            />
          </FlexContainer>
          <FlexContainer>
            <SelectOptions
              placeholder={"Select"}
              label={"Currency"}
              name={"currencyId"}
              handleSelect={handleChange}
              value={currencyId}
              options={currencyItems}
            />
            <SelectOptions
              placeholder={"Select"}
              label={"Supplier"}
              name={"supplier"}
              handleSelect={handleChange}
              value={supplier}
              options={supplierItems}
            />
            <SelectOptions
              placeholder={"Select"}
              label={"Category"}
              name={"category"}
              handleSelect={handleChange}
              value={category}
              options={categoryItems}
            />
          </FlexContainer>
          <FlexContainer>
            <TextInput
              label={"MFT Date"}
              type={"date"}
              name={"mtfDate"}
              handleChange={handleChange}
              value={formatDate(mtfDate)}
            />
            <TextInput
              label={"Exp Date"}
              type={"date"}
              name={"expDate"}
              handleChange={handleChange}
              value={formatDate(expDate)}
            />
            <FIleInput
              label={"Stock Image"}
              type={"tel"}
              name={"imageUrl"}
              handleChange={handleImageSelection}
            />
          </FlexContainer>
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleSubmit}
              textColor={theme.white}
              loading={addingStock}
              disabled={
                !batch ||
                !costPrice ||
                !sellingPrice ||
                !qty ||
                !supplier ||
                !category ||
                !mtfDate ||
                !expDate ||
                addingStock ||
                !name ||
                !description ||
                !imageUrl ||
                !currencyId
              }
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default EditStockModal;
