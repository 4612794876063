import React from "react";
import styled from "styled-components";
import { media } from "../../Screens";
import { GiCancel } from "react-icons/gi";
import { removeItemFromWaitlist } from "../../Redux/Slices/SalesSlice";
import { useDispatch } from "react-redux";

const Container = styled.div`
  width: 100%;
  height: auto;
  opacity: 0px;
  box-shadow: 1.11px 2.23px 5.23px 0px #00000012;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10px;
  padding: 10px;
  position: relative;

  ${media.tablet} {
    height: 69.82px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47.06px;
  height: 46.54px;
  border-radius: 8.91px;
  opacity: 0px;
  background: #211f7e1a;
  text-align: center;
  border-bottom: 1.11px solid ${({ theme }) => theme.purple};
  font-size: 17px;
  font-weight: 500;
  line-height: 20.72px;
  color: ${({ theme }) => theme.blue};
  cursor: pointer;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const IdNumber = styled.div`
  width: 100%;
  height: 12.61px;
  border-radius: 4.46px;
  background: ${({ theme }) => theme.blue};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
  padding-left: 10px;
  font-size: 8.91px;
  font-weight: 400;
  line-height: 10.86px;
  color: ${({ theme }) => theme.white};

  span {
    width: 3.92px;
    height: 3.88px;
    border-radius: 50%;
    background: ${({ theme }) => theme.white};
  }
`;

const CustomerName = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 10px;
  font-weight: 500;
  line-height: 11.74px;
  color: ${({ theme }) => theme.black};

  img {
    width: 5.88px;
    height: 5.82px;
  }
`;

const ItemsCount = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 10px;
  font-weight: 500;
  line-height: 5.74px;
  color: ${({ theme }) => theme.black5};

  img {
    width: 5.88px;
    height: 5.82px;
  }
`;

const WaitingListCard = ({
  id,
  indexNumber,
  customerName,
  itemsCount,
  handleClick,
}) => {
  const dispatch = useDispatch();

  return (
    <Container>
      <LeftContent onClick={handleClick}>#{id}</LeftContent>
      <RightContent>
        <IdNumber>
          <span></span>
          {indexNumber}
        </IdNumber>
        <CustomerName>
          <img src="/assets/images/Sales/customer.svg" alt="customer" />
          {customerName}
        </CustomerName>
        <ItemsCount>
          <img src="/assets/images/Sales/items.svg" alt="items" />
          {itemsCount} items
        </ItemsCount>
      </RightContent>
      <GiCancel
        style={{ position: "absolute", right: "2px", top: "2px" }}
        cursor={"pointer"}
        onClick={() =>
          dispatch(removeItemFromWaitlist({ patientName: customerName, id }))
        }
      />
    </Container>
  );
};

export default WaitingListCard;
