import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRrefreshed = (token) => {
  refreshSubscribers.map((callback) => callback(token));
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

api.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const token = user?.accessToken;
    const refreshToken = user?.refreshToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["x-refresh-auth"] = refreshToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          addRefreshSubscriber((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const user = JSON.parse(localStorage.getItem("user") || "{}");
      const refreshToken = user?.refreshToken;

      if (!refreshToken) {
        logoutUser();
        return Promise.reject(error);
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/v1/pharmacy-administrators/refresh-auth`,
          {}, // Empty body since refresh token is passed in headers
          {
            headers: {
              "x-refresh-auth": refreshToken, // Add refresh token to the headers
            },
          }
        );

        const newToken = response.data.accessToken;
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            accessToken: newToken, // Update the access token
          })
        );

        api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
        isRefreshing = false;
        onRrefreshed(newToken);
        refreshSubscribers = [];

        return api(originalRequest);
      } catch (err) {
        logoutUser();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

const logoutUser = () => {
  localStorage.clear();
  window.location.href = "/"; // Redirect to the login page
};

export default api;
