import React, { useEffect, useState } from "react";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAllModalStack,
  setCurrentModal,
} from "../../../../Redux/Slices/navSlice";
import SearchInputDropdown from "../../../../components/Inputs/SearchInputDropdown";
import { mapToLabelValueArray } from "../../../../helpers";
import { selectProduct } from "../../../../Redux/Slices/ProductSlice";
import { searchProducts } from "../../../../Apis/product";
import { selectCurrency } from "../../../../Redux/Slices/currencySlice";
import SelectOptions from "../../../../components/Inputs/SelectOptions";
import CustomModalStack from "../../../../components/Modals/CustomModalStack";
import { selectInventory } from "../../../../Redux/Slices/InventorySlice";
import {
  addItemToInvoice,
  getInvoiceToViewItems,
} from "../../../../Apis/Inventory";
import { BiTrash } from "react-icons/bi";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  th,
  td {
    border: 1px solid ${({ theme }) => theme.lightGrey};
    padding: 10px;
    text-align: left;
  }

  th {
    background-color: ${({ theme }) => theme.lightGrey};
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.lightBackground};
  }
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.red};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.lightRed};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`;

const AddProductButton = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.blue};
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.red};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
`;

const EditInvoiceModal = ({ invoice }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);
  const [error, setError] = useState("");
  const [productDetails, setProductDetails] = useState({
    productId: "",
    quantity: "",
    costPrice: "",
    sellingPrice: "",
    currencyId: "",
    dateOfExpiration: "",
    dateOfManufacture: "",
  });

  const { searchedProducts, productToSearch } = useSelector(selectProduct);
  const { currencies } = useSelector(selectCurrency);
  const { addingInvoiceItems, selectedInvoiceToViewItems } =
    useSelector(selectInventory);

  const handleProductDetailChange = (e) => {
    const { name, value } = e?.target || e;
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleAddProduct = () => {
    const {
      productId,
      quantity,
      costPrice,
      sellingPrice,
      currencyId,
      dateOfExpiration,
      dateOfManufacture,
    } = productDetails;

    const newProductCost = parseFloat(costPrice || 0) * parseInt(quantity || 0);
    const totalCost = productList.reduce(
      (acc, product) =>
        acc + parseFloat(product.costPrice) * parseInt(product.quantity),
      0
    );

    const newTotalCost = totalCost + newProductCost;

    if (
      newTotalCost >
      invoice?.amount -
        selectedInvoiceToViewItems?.items.reduce(
          (sum, item) => sum + parseFloat(item.quantity * item.costPrice),
          0
        )
    ) {
      setError(
        `The total cost price (${newTotalCost.toFixed(
          2
        )}) exceeds the invoice balance (${
          invoice?.amount -
          selectedInvoiceToViewItems?.items.reduce(
            (sum, item) => sum + parseFloat(item.quantity * item.costPrice),
            0
          )
        }). Please adjust the cost price or quantity.`
      );
      return;
    }
    setError("");
    if (
      productId &&
      quantity &&
      costPrice &&
      sellingPrice &&
      currencyId &&
      dateOfExpiration &&
      dateOfManufacture
    ) {
      setProductList([...productList, productDetails]);
      setProductDetails({
        productId: "",
        quantity: "",
        costPrice: "",
        sellingPrice: "",
        currencyId: "",
        dateOfExpiration: "",
        dateOfManufacture: "",
      });
    }
  };

  const handleRemoveProduct = (index) => {
    const updatedList = productList.filter((_, i) => i !== index);
    setProductList(updatedList);
  };

  const handleClose = () => {
    setProductDetails({
      productId: "",
      quantity: "",
      costPrice: "",
      sellingPrice: "",
      currencyId: "",
      dateOfExpiration: "",
      dateOfManufacture: "",
    });
    setProductList([]);
    dispatch(closeAllModalStack(""));
    setError("")
  };

  const handleCreate = async () => {
    try {
      // Await the completion of the asynchronous function
      await addItemToInvoice({
        id: invoice?.id,
        productList,
      });
      getInvoiceToViewItems(invoice?.id);
      // Call handleClose only after the above action is completed
      handleClose();
    } catch (error) {
      console.error("Error adding items to invoice:", error);
      // Optionally, handle errors if the action fails
    }
  };

  useEffect(() => {
    searchProducts(null, productToSearch);
  }, [productToSearch]);

  useEffect(() => {
    invoice?.id && getInvoiceToViewItems(invoice?.id);
  }, [invoice?.id]);

  return (
    <CustomModalStack
      id="edit_invoice"
      content={
        <Container>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ModalTitle>Add Invoice Items</ModalTitle>
          <FlexContainer>
            <SearchInputDropdown
              placeholder={"Search Product"}
              label={"Product"}
              name="productId"
              value={productDetails.productId}
              items={mapToLabelValueArray(
                searchedProducts?.items,
                "name",
                "id"
              )}
              handleSelect={(name, value) =>
                handleProductDetailChange({ name, value })
              }
            />
            <TextInput
              label={"Quantity"}
              placeholder={"Enter quantity"}
              name="quantity"
              value={productDetails.quantity}
              handleChange={handleProductDetailChange}
              type="number"
            />
          </FlexContainer>
          <FlexContainer>
            <TextInput
              label={"Cost Price (₦)"}
              placeholder={"Enter cost price"}
              name="costPrice"
              value={productDetails.costPrice}
              handleChange={handleProductDetailChange}
              type="number"
            />
            <TextInput
              label={"Selling Price (₦)"}
              placeholder={"Enter selling price"}
              name="sellingPrice"
              value={productDetails.sellingPrice}
              handleChange={handleProductDetailChange}
              type="number"
            />
          </FlexContainer>
          <FlexContainer>
            <SelectOptions
              placeholder={"Select Currency"}
              label={"Currency"}
              name="currencyId"
              value={productDetails.currencyId}
              options={mapToLabelValueArray(currencies?.items, "name", "id")}
              handleSelect={handleProductDetailChange}
            />
            <TextInput
              label={"Expiry Date"}
              name="dateOfExpiration"
              value={productDetails.dateOfExpiration}
              handleChange={handleProductDetailChange}
              type="date"
              restrictBackdate={true}
            />
          </FlexContainer>
          <FlexContainer style={{ alignItems: "last baseline" }}>
            <TextInput
              label={"Manufacture Date"}
              name="dateOfManufacture"
              value={productDetails.dateOfManufacture}
              handleChange={handleProductDetailChange}
              type="date"
              restrictForwardDate={true}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Add to List"}
              handleClick={handleAddProduct}
              textColor={theme.white}
            />
          </FlexContainer>
          {productList.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Cost Price</th>
                  <th>Selling Price</th>
                  <th>Expiry Date</th>
                  <th>Manufacture Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {productList.map((product, index) => {
                  const productName = searchedProducts?.items?.find(
                    (item) => item.id === product.productId
                  )?.name;
                  const currencySymbol = currencies?.items?.find(
                    (currency) =>
                      Number(currency.id) === Number(product.currencyId)
                  )?.symbol;

                  return (
                    <tr key={index}>
                      <td>{productName}</td>
                      <td>{product.quantity}</td>
                      <td>
                        {currencySymbol} {product.costPrice}
                      </td>
                      <td>
                        {currencySymbol}
                        {product.sellingPrice}
                      </td>
                      <td>{product.dateOfExpiration}</td>
                      <td>{product.dateOfManufacture}</td>
                      <td>
                        <RemoveButton
                          onClick={() => handleRemoveProduct(index)}
                        >
                          <BiTrash />
                        </RemoveButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          <div>
            <span>Can't find your product? </span>
            <AddProductButton
              onClick={() => dispatch(setCurrentModal("add_new_product"))}
            >
              Add product
            </AddProductButton>
          </div>
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={handleClose}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleCreate}
              textColor={theme.white}
              disabled={addingInvoiceItems || productList.length === 0}
              loading={addingInvoiceItems}
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default EditInvoiceModal;
