import { toast } from "react-toastify";
import {
  setAddingCustomers,
  setCustomers,
  setDeletingCustomers,
  setEditingCustomers,
  setGettingCustomers,
} from "../Redux/Slices/CustomerSlice";
import { store } from "../Redux/app/store";
import api from "./baseUri";
import { setCurrentModal } from "../Redux/Slices/navSlice";

export const getCustomers = async (page, pageSize, searchParams) => {
  store.dispatch(setGettingCustomers(true));
  await api
    .get(
      `v1/pharmacy-customers?page=${page}&pageSize=${pageSize}&searchText=${searchParams}`
    )
    .then((res) => {
      store.dispatch(setCustomers(res.data)); // Adjust according to your response structure
      store.dispatch(setGettingCustomers(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingCustomers(false));
    });
};

export const addCustomer = async (customerData) => {
  store.dispatch(setAddingCustomers(true));
  await api
    .post("v1/pharmacy-customers", customerData)
    .then(async (res) => {
      store.dispatch(setAddingCustomers(false));
      toast.success(res?.message);
      store.dispatch(setCurrentModal("add_new_customer_success"));
      await getCustomers(1, 20); // Refetch customers
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingCustomers(false));
    });
};

export const editCustomer = async (id, customerData) => {
  store.dispatch(setEditingCustomers(true));
  await api
    .patch(`v1/pharmacy-customers/${id}`, customerData)
    .then(async (res) => {
      const { customersPage, customerspageSize } = store.getState().customer;
      store.dispatch(setEditingCustomers(false));
      toast.success(res?.message);
      store.dispatch(setCurrentModal("edit_customer_success"));
      await getCustomers(customersPage, customerspageSize); // Refetch customers or update state
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setEditingCustomers(false));
    });
};

export const deleteCustomer = async (customerId) => {
  store.dispatch(setDeletingCustomers(true));
  await api
    .delete(`v1/pharmacy-customers/${customerId}`)
    .then(async (res) => {
      const { customersPage, customerspageSize } = store.getState().customer;
      store.dispatch(setDeletingCustomers(false));
      store.dispatch(setCurrentModal("delete_customer_success"));
      toast.success(res?.message);
      await getCustomers(customersPage, customerspageSize); // Refetch customers or update state
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setDeletingCustomers(false));
    });
};
