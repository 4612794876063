import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../components/Table/CustomTable";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { TableActions } from "../Online Order/styled";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { getProducts } from "../../../Apis/product";
import {
  getProductStateUpdate,
  selectProduct,
  setProductsPage,
  setProductspageSize,
  setSelectedProductToEdit,
  // setSelectedProductToEdit,
} from "../../../Redux/Slices/ProductSlice";
import { useNavigate } from "react-router-dom";

export const columns = [
  { label: "Product ID", field: "product_id" },
  { label: "Product Name", field: "name" },
  { label: "Brand Name", field: "brand_name" },
  { label: "Strength", field: "strength" },
  { label: "Form", field: "form" },
  { label: "Manufacturer", field: "manufacturer" },
  { label: "Unit Size", field: "unit_size" },
  { label: "Date Added", field: "date_added" },
  { label: "Action", field: "action" },
];

const ProductTable = () => {
  const { productsPage, productsPageSize, products, gettingProducts } =
    useSelector(selectProduct);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const rows = products.items?.map((product, index) => {
    return {
      product_id: index + 1,
      name: product?.name,
      brand_name: product?.brandName,
      category: product?.categoryId,
      unit_size: product?.unitSize,
      strength: product?.strength,
      form: product?.form,
      manufacturer: product?.manufacturer,
      date_added: moment(product?.createdAt).format("Do MMM YYYY"),
      action: (
        <TableActions>
          <div
            onClick={() => {
              dispatch(setSelectedProductToEdit(product));
              navigate("/product-management/view-product-details");
            }}
            style={{ color: theme.purple, fontSize: "16px", cursor: "pointer" }}
          >
            View Details
          </div>
          <img
            src="/assets/images/Generals/delete.svg"
            alt="delete_icon"
            onClick={() => {
              dispatch(setCurrentModal("delete_product"));
              dispatch(
                getProductStateUpdate({ name: "productId", value: product?.id })
              );
            }}
          />
        </TableActions>
      ),
    };
  });

  const data = {
    columns,
    rows,
  };

  useEffect(() => {
    getProducts(productsPage, productsPageSize);
  }, [productsPage, productsPageSize]);

  return (
    <>
      <CustomTable
        data={data}
        noRecord={!gettingProducts && products?.totalItems === 0}
        title={"Product List"}
        showHeader={true}
        showPagination={true}
        loading={gettingProducts}
        filter={
          <FilledButton
            bgColor={theme.purple}
            btnText={"Add Product +"}
            textColor={theme.white}
            handleClick={() => dispatch(setCurrentModal("add_new_product"))}
          />
        }
        page={productsPage}
        limit={productsPageSize}
        handlePageChange={(value) => {
          dispatch(setProductsPage(value));
        }}
        handlePageLimitChange={(value) => {
          dispatch(setProductspageSize(value));
        }}
        totalPages={products?.totalPages}
      />
    </>
  );
};

export default ProductTable;
