import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModalStack,
  selectNav,
} from "../../Redux/Slices/navSlice";
import styled from "styled-components";
import { media } from "../../Screens";

const CustomModalStackContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 100000000;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
`;

const ContentContainer = styled.div`
  position: relative;
  background: #fff;
  width: 90%;
  height: auto;
  padding: 20px;
  min-height: 359px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 1000000000000;

  ${media.phone} {
    width: auto;
    min-width: 389px;
    max-width: 60%;
  }
`;

const Close = styled.img`
  align-self: flex-end;
  width: 8px;
  cursor: pointer;
`;

const CustomModalStack = ({ content, id }) => {
  const { modalStack } = useSelector(selectNav);
  const dispatch = useDispatch();

  const isActive = modalStack.includes(id); // Check if the modal is active
  const isTopMost = modalStack[modalStack?.length - 1] === id; // Check if it's the topmost modal

  return (
    <>
      {isActive && (
        <CustomModalStackContainer
          id="modal_parent"
          onClick={(e) => {
            if (e.target.id === "modal_parent" && isTopMost) {
              dispatch(closeModalStack(id));
            }
          }}
        >
          <ContentContainer>
            <Close
              src="/assets/images/Generals/modal_close.svg"
              onClick={() => isTopMost && dispatch(closeModalStack(id))}
            />
            {content}
          </ContentContainer>
        </CustomModalStackContainer>
      )}
    </>
  );
};

export default CustomModalStack;
