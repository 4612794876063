import { toast } from "react-toastify";
import {
  setAddingStock,
  setDeletingStock,
  setGettingStocks,
  setStocks,
  setSearchedStocks,
  setSearchingStocks,
} from "../Redux/Slices/StockSlice";
import { store } from "../Redux/app/store";
import api from "./baseUri";
import { setCurrentModal } from "../Redux/Slices/navSlice";

export const addStock = async (StockDetails) => {
  store.dispatch(setAddingStock(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .post(`v1/pharmacies/${pharmacy?.id}/Stocks`, StockDetails, {
      headers: {
        "Content-Type": "multipart/form-data", // Let Axios handle the content-type for file uploads
      },
    })
    .then((res) => {
      const { StockPage, StockPageSize } = store.getState().Stocks;
      toast.success(res.data.message);
      store.dispatch(setAddingStock(false));
      getStocks(StockPage, StockPageSize);
      store.dispatch(setCurrentModal("add_new_Stock_success"));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingStock(false));
    });
};

export const editStock = async (StockDetails) => {
  store.dispatch(setAddingStock(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .patch(`v1/pharmacies/${pharmacy?.id}/Stocks`, StockDetails, {
      headers: {
        "Content-Type": "multipart/form-data", // Let Axios handle the content-type for file uploads
      },
    })
    .then((res) => {
      const { StockPage, StockPageSize } = store.getState().Stocks;
      toast.success(res.data.message);
      store.dispatch(setAddingStock(false));
      getStocks(StockPage, StockPageSize);
      store.dispatch(setCurrentModal("edit_Stock_success"));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingStock(false));
    });
};

export const getStocks = async (page, pageSize) => {
  store.dispatch(setGettingStocks(true));

  await api
    .get(`v1/inventory-items?page=${page}&pageSize=${pageSize}`)
    .then((res) => {
      store.dispatch(setStocks(res.data)); // Assuming res.data.Stocks holds the Stock data
      store.dispatch(setGettingStocks(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingStocks(false));
    });
};

export const searchStocks = async (page, searchText, categoryId, productId) => {
  store.dispatch(setSearchingStocks(true));

  // Build the query string dynamically
  const queryParams = new URLSearchParams();

  if (page !== null && page !== undefined) queryParams.append("page", page);
  if (searchText) queryParams.append("searchText", searchText);
  if (categoryId) queryParams.append("categoryId", categoryId);
  if (productId) queryParams.append("productId", productId);
  await api
    .get(`v1/inventory-items?${queryParams.toString()}`)
    .then((res) => {
      store.dispatch(setSearchedStocks(res.data)); // Assuming res.data.Stocks holds the Stock data
      store.dispatch(setSearchingStocks(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setSearchingStocks(false));
    });
};

export const deleteStock = async (StockId) => {
  store.dispatch(setDeletingStock(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .delete(`v1/pharmacies/${pharmacy.id}/Stocks/${StockId}`)
    .then((res) => {
      toast.success(res.data.message);
      store.dispatch(setDeletingStock(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setDeletingStock(false));
    });
};
