import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../components/Table/CustomTable";
import { TableActions } from "../Online Order/styled";
import {
  setSelectedStock,
  setStocksPage,
  setStockspageSize,
} from "../../../Redux/Slices/StockSlice";
import { getStocks } from "../../../Apis/StockManagement";
import { selectStock } from "../../../Redux/Slices/StockSlice";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";

export const columns = [
  { label: "Stock ID", field: "stock_id" },
  { label: "product Name", field: "product" },
  { label: "Category", field: "category" },
  // { label: "Expiry Date", field: "expiry_date" },
  { label: "Quantity", field: "quantity" },
  { label: "Price", field: "price" },
  { label: "Date Received", field: "date_received" },
  { label: "Last Updated", field: "last_updated" },
  { label: "Action", field: "action" },
];

const StockTable = () => {
  const { StocksPage, StocksPageSize, Stocks, gettingStocks } =
    useSelector(selectStock);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rows = Stocks.items?.map((Stock, index) => {
    return {
      stock_id: index + 1,
      product: Stock?.product?.name,
      expiry_date: moment(Stock?.expiryDate).format("Do MMM YYYY"),
      category: Stock?.product?.category?.name,
      batch_number: Stock?.product?.batch,
      quantity: Stock?.quantity,
      selling_price: Stock?.currency?.symbol + " " + Stock?.price,
      price: Stock?.currency?.symbol + " " + Stock?.price,
      date_received: moment(Stock?.dateReceived).format("Do MMM YYYY"),
      last_updated: moment(Stock?.updatedAt).format("Do MMM YYYY"),
      action: (
        <TableActions>
          <div
            onClick={() => {
              dispatch(setSelectedStock(Stock));
              navigate(`/stock-details/${Stock?.id}`);
            }}
            style={{ color: theme.purple, fontSize: "16px", cursor: "pointer" }}
          >
            View Details
          </div>
        </TableActions>
      ),
    };
  });

  const data = {
    columns,
    rows,
  };

  useEffect(() => {
    getStocks(StocksPage, StocksPageSize);
  }, [StocksPage, StocksPageSize]);

  return (
    <>
      <CustomTable
        data={data}
        noRecord={!gettingStocks && Stocks?.totalItems === 0}
        title={"Stock List"}
        showHeader={true}
        showPagination={true}
        loading={gettingStocks}
        page={StocksPage}
        limit={StocksPageSize}
        handlePageChange={(value) => {
          dispatch(setStocksPage(value));
        }}
        handlePageLimitChange={(value) => {
          dispatch(setStockspageSize(value));
        }}
        totalPages={Stocks?.totalPages}
      />
    </>
  );
};

export default StockTable;
