import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingCategories: false,
  gettingProductCategories: false,
  addingCategory: false,
  deletingCategory: false,

  // Category details
  categoryName: "", // The only detail to update
  selectedCategoryToEdit: null, // Selected category for editing
  categories: [], // List of categories
  productCategories: [],
  categoriesPageSize: 20, // Number of categories per page
  categoriesPage: 1, // Current page of categories
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    getCategoryStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingCategories
    setGettingCategories: (state, action) => {
      state.gettingCategories = action.payload;
    },
    setGettingProductCategories: (state, action) => {
      state.gettingProductCategories = action.payload;
    },

    // Reducer to update addingCategory
    setAddingCategory: (state, action) => {
      state.addingCategory = action.payload;
    },

    // Reducer to update deletingCategory
    setDeletingCategory: (state, action) => {
      state.deletingCategory = action.payload;
    },

    // Reducer to update categoryName
    setCategoryName: (state, action) => {
      state.categoryName = action.payload;
    },

    // Reducer to update selectedCategoryToEdit
    setSelectedCategoryToEdit: (state, action) => {
      state.selectedCategoryToEdit = action.payload;
    },

    // Reducer to update categories
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setProductCategories: (state, action) => {
      state.productCategories = action.payload;
    },

    // Reducer to update categoriespageSize
    setCategoriespageSize: (state, action) => {
      state.categoriespageSize = action.payload;
    },

    // Reducer to update categoriesPage
    setCategoriesPage: (state, action) => {
      state.categoriesPage = action.payload;
    },
  },
});

export const {
  getCategoryStateUpdate,
  setGettingCategories,
  setAddingCategory,
  setDeletingCategory,
  setCategoryName,
  setSelectedCategoryToEdit,
  setCategories,
  setCategoriespageSize,
  setCategoriesPage,
  setProductCategories,
  setGettingProductCategories
} = categorySlice.actions;

export const selectCategory = (state) => state.categories;

export default categorySlice.reducer;
