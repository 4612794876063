import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import {
  getSalesStateUpdate,
  selectSales,
} from "../../../../Redux/Slices/SalesSlice";
import { printInvoice } from "../../../../Apis/Sales";
import { toast } from "react-toastify";
import SearchInputDropdown from "../../../../components/Inputs/SearchInputDropdown";
import { getCustomers } from "../../../../Apis/CustomerManagement";
import { selectCustomer } from "../../../../Redux/Slices/CustomerSlice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const PatientNameModal = ({ handlePrint }) => {
  const [customerNames, setCustomerName] = useState();
  const { patientName, salesCart, discount, printingInvoice } =
    useSelector(selectSales);
  const { customers, customersPage, customerspageSize } =
    useSelector(selectCustomer);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handlePrintInvoice = async (e) => {
    e.preventDefault();
    const invoice = {
      customerName: patientName,
      currencyId: 1,
      discountAmount: discount,
      items: salesCart.cartItems.map((item) => ({
        quantity: item.count,
        discountAmount: 0, // Assuming no discount is applied here
        inventoryStockId: item.id,
      })),
    };
    try {
      await printInvoice(invoice); // Assuming this is an async API call
      handlePrint(); // Trigger the print operation
    } catch (error) {
      toast.error("Failed to prepare the invoice for printing.");
    }
  };

  const handleChange = (name, value) => {
    dispatch(getSalesStateUpdate({ name, value }));
  };

  useEffect(() => {
    getCustomers(customersPage, customerspageSize, patientName);
  }, [dispatch, customersPage, customerspageSize, patientName]);

  useEffect(() => {
    const filter = customers?.items?.map((x) => {
      return x?.name + ` (${x?.email})`;
    });
    setCustomerName(filter);
  }, [customers]);

  return (
    <CustomModal
      id="patient_name"
      content={
        <Container>
          <ModalTitle>Patient Detail</ModalTitle>
          <SearchInputDropdown
            placeholder={"Enter patient name"}
            label={"Patient Name"}
            value={patientName}
            name={"patientName"}
            handleSelect={handleChange}
            items={customerNames}
          />
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Proceed"}
              handleClick={handlePrintInvoice}
              textColor={theme.white}
              loading={printingInvoice}
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default PatientNameModal;
