import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  printingInvoice: false,

  waitingLists: [],
  salesCart: {
    patientId: null,
    patientName: "",
    cartItems: [],
  },
  summary: 0,
  vat: 0,
  discount: 0,
  total: 0,
};

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    getSalesStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
    calculateTotals: (state) => {
      state.summary = state.salesCart.cartItems.reduce(
        (acc, item) => acc + Number(item.price) * item.count,
        0
      );
      state.vat = 0; // 7% VAT
      state.discount = 0; // 4% discount
      state.total = state.summary + state.vat - state.discount;
    },

    addToSalesCart: (state, action) => {
      const { id, quantity } = action.payload;
      const availableQuantity = Number(quantity); // Ensure quantity is a number

      const updateItemCount = (items, errorMessage) => {
        const existingItem = items.find((item) => item.id === id);
        if (existingItem) {
          const currentCount = Number(existingItem.count); // Ensure count is a number
          if (currentCount < availableQuantity) {
            existingItem.count += 1;
          } else {
            toast.error(errorMessage);
            return false;
          }
        } else if (availableQuantity > 0) {
          items.push({ ...action.payload, count: 1 });
        } else {
          toast.error(errorMessage);
          return false;
        }
        return true;
      };

      // Update sales cart
      const cartUpdated = updateItemCount(
        state.salesCart.cartItems,
        `Cannot add more than ${availableQuantity} items to the cart.`
      );

      if (!cartUpdated) return;

      // Update waiting list if the patient exists
      const waitlistEntry = state.waitingLists.find(
        (entry) => entry.patientName === state.salesCart.patientName
      );
      if (waitlistEntry) {
        updateItemCount(
          waitlistEntry.cartItems,
          `Cannot add more than ${availableQuantity} items to the waiting list.`
        );
      }

      // Recalculate totals
      salesSlice.caseReducers.calculateTotals(state);
    },

    updateCountDynamically: (state, action) => {
      const { id, amount, quantity } = action.payload;

      // Find item in the cart
      const cartItem = state.salesCart.cartItems.find((item) => item.id === id);
      if (cartItem) {
        if (amount > quantity) {
          toast.error(`Cannot set count greater than ${quantity}.`);
          return;
        }
        cartItem.count = amount;
      }

      // Update the waiting list if patient exists
      const waitlistEntry = state.waitingLists.find(
        (entry) => entry.patientName === state.salesCart.patientName
      );
      if (waitlistEntry) {
        const waitlistItem = waitlistEntry.cartItems.find(
          (item) => item.id === id
        );
        if (waitlistItem) {
          if (amount > quantity) {
            toast.error(
              `Cannot set count greater than ${quantity} in the waiting list.`
            );
            return;
          }
          waitlistItem.count = amount;
        }
      }

      // Recalculate totals after updating count
      salesSlice.caseReducers.calculateTotals(state);
    },

    removeFromSalesCart: (state, action) => {
      const existingItem = state.salesCart.cartItems.find(
        (item) => item.id === action.payload.id
      );
      if (existingItem) {
        if (existingItem.count > 1) {
          existingItem.count -= 1; // Decrease the count
        } else {
          state.salesCart.cartItems = state.salesCart.cartItems.filter(
            (item) => item.id !== action.payload.id
          ); // Remove the item if count is 1
        }
      }

      // Recalculate totals
      salesSlice.caseReducers.calculateTotals(state);

      // Update the waiting list if patient exists
      const waitlistEntry = state.waitingLists.find(
        (entry) => entry.patientName === state.salesCart.patientName
      );
      if (waitlistEntry) {
        const existingWaitlistItem = waitlistEntry.cartItems.find(
          (item) => item.id === action.payload.id
        );
        if (existingWaitlistItem) {
          if (existingWaitlistItem.count > 1) {
            existingWaitlistItem.count -= 1; // Decrease the count
          } else {
            waitlistEntry.cartItems = waitlistEntry.cartItems.filter(
              (item) => item.id !== action.payload.id
            ); // Remove the item if count is 1
          }
        }
      }
    },
    removeItemFromSalesCart: (state, action) => {
      // Remove item completely from salesCart
      state.salesCart.cartItems = state.salesCart.cartItems.filter(
        (item) => item.id !== action.payload.id
      );

      // Recalculate totals
      salesSlice.caseReducers.calculateTotals(state);

      // Update the waiting list if patient exists
      const waitlistEntry = state.waitingLists.find(
        (entry) => entry.patientName === state.salesCart.patientName
      );
      if (waitlistEntry) {
        // Remove the item from the waitlist's cartItems
        waitlistEntry.cartItems = waitlistEntry.cartItems.filter(
          (item) => item.id !== action.payload.id
        );

        // If the waitlist's cartItems is empty, remove the waitlist entry
        if (waitlistEntry.cartItems.length === 0) {
          state.waitingLists = state.waitingLists.filter(
            (entry) => entry.patientName !== state.salesCart.patientName
          );
        }
      }
    },
    removeItemFromWaitlist: (state, action) => {
      const { id, patientName } = action.payload;

      // Filter out the object from waitingLists based on matching id and patientName
      state.waitingLists = state.waitingLists.filter(
        (waitlistEntry) =>
          waitlistEntry.id !== id && waitlistEntry.patientName !== patientName
      );
    },

    addToWaitlist: (state, action) => {
      // Create a new object with patientId, patientName, and cartItems (salesCart)
      const newWaitlistEntry = {
        patientId: action.payload.patientId,
        patientName: action.payload.patientName,
        cartItems: [...state.salesCart.cartItems], // Add a copy of the cartItems
      };

      // Add the entry to the waiting list
      state.waitingLists.push(newWaitlistEntry);

      // Clear the salesCart after adding to waitlist
      state.salesCart = {
        patientId: null,
        patientName: "",
        cartItems: [],
      };

      // Reset totals after clearing the cart
      state.summary = 0;
      state.vat = 0;
      state.discount = 0;
      state.total = 0;
    },
    setSalesCart: (state, action) => {
      // Set the salesCart to the clicked waitlist
      state.salesCart = action.payload;

      // Recalculate totals after setting salesCart
      salesSlice.caseReducers.calculateTotals(state);
    },
    clearSalesCart: (state) => {
      state.salesCart = {
        patientId: null,
        patientName: "",
        cartItems: [],
      };
    },
    setPrintingInvoice: (state, action) => {
      state.printingInvoice = action.payload;
    },
  },
});

export const {
  getSalesStateUpdate,
  addToSalesCart,
  removeFromSalesCart,
  removeItemFromSalesCart,
  addToWaitlist,
  clearSalesCart,
  setSalesCart,
  removeItemFromWaitlist,
  setPrintingInvoice,
  updateCountDynamically,
} = salesSlice.actions;

export const selectSales = (state) => state.sales;

export default salesSlice.reducer;
