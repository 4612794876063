import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingProducts: false,
  addingProduct: false,
  deletingProduct: false,

  // Product pagination
  products: [], // List of products
  productsPageSize: 20, // Number of products per page
  productsPage: 1, // Current page of products
  searchedProducts: [],
  searchingProducts: false,
  searchProductPage: 1,
  productToSearch: "",
  productByCategoryId: null,
  productId: null,

  // Product details
  name: "",
  imageUrl: null,
  currencyId: "",
  description: "",
  invoiceNumber: "",
  batch: "",
  costPrice: 0,
  sellingPrice: 0,
  qty: 0,
  supplier: null,
  category: null,
  mtfDate: null,
  expDate: null,
  product_name: "",
  brand_name: "",
  generic_name: "",
  strength: "",
  form: "",
  manufacturer: "",
  barcode: "",
  unit_size: "",
  unit_type: "",
  atcClassificationId: "",
  selectedProductToEdit: null, // Selected product for Editing
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    getProductStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingProducts
    setGettingProducts: (state, action) => {
      state.gettingProducts = action.payload;
    },

    // Reducer to update searchingProducts
    setSearchingProducts: (state, action) => {
      state.searchingProducts = action.payload;
    },

    // Reducer to update addingProduct
    setAddingProduct: (state, action) => {
      state.addingProduct = action.payload;
    },

    // Reducer to update deletingProduct
    setDeletingProduct: (state, action) => {
      state.deletingProduct = action.payload;
    },

    // Reducer to update products
    setProducts: (state, action) => {
      state.products = action.payload;
    },

    // Reducer to update searched products
    setSearchedProducts: (state, action) => {
      state.searchedProducts = action.payload;
    },

    // Reducer to update productspageSize
    setProductspageSize: (state, action) => {
      state.productsPageSize = action.payload;
    },

    // Reducer to update productsPage
    setProductsPage: (state, action) => {
      state.productsPage = action.payload;
    },

    // Reducer to update productBarCode
    setProductBarCode: (state, action) => {
      state.productBarCode = action.payload;
    },

    // Reducer to update productCategory
    setProductCategory: (state, action) => {
      state.productCategory = action.payload;
    },

    // Reducer to update productName
    setProductName: (state, action) => {
      state.productName = action.payload;
    },

    // Reducer to update productExpiryDate
    setProductExpiryDate: (state, action) => {
      state.productExpiryDate = action.payload;
    },

    // Reducer to update productPrice
    setProductPrice: (state, action) => {
      state.productPrice = action.payload;
    },

    // Reducer to update productInStock
    setProductInStock: (state, action) => {
      state.productInStock = action.payload;
    },

    // Reducer to update store
    setStore: (state, action) => {
      state.store = action.payload;
    },

    // Reducer to update status
    setStatus: (state, action) => {
      state.status = action.payload;
    },

    // Reducer to update minQuantity
    setMinQuantity: (state, action) => {
      state.minQuantity = action.payload;
    },

    // Reducer to update selectedProductToEdit
    setSelectedProductToEdit: (state, action) => {
      state.selectedProductToEdit = action.payload;
      state.name = action.payload?.name;
      state.currencyId = action.payload?.currencyId;
      state.description = action.payload?.description;
      state.batch = action.payload?.batch;
      state.costPrice = action.payload.costPrice;
      state.sellingPrice = action.payload.sellingPrice;
      state.qty = action.payload.quantity;
      state.supplier = action.payload.supplierId;
      state.category = action.payload.categoryId;
      state.mtfDate = action.payload.dateOfManufacture;
      state.expDate = action.payload.dateOfExpiration;
    },
  },
});

export const {
  getProductStateUpdate,
  setGettingProducts,
  setAddingProduct,
  setDeletingProduct,
  setProducts,
  setProductspageSize,
  setProductsPage,
  setProductBarCode,
  setProductCategory,
  setProductName,
  setProductExpiryDate,
  setProductPrice,
  setProductInStock,
  setStore,
  setStatus,
  setMinQuantity,
  setSelectedProductToEdit,
  setSearchedProducts,
  setSearchingProducts,
} = productSlice.actions;

export const selectProduct = (state) => state.products;

export default productSlice.reducer;
