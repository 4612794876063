import React from "react";
import styled, { useTheme } from "styled-components";
import RoundedButton from "../Buttons/RoundedButton";
import { media } from "../../Screens";
import { useDispatch } from "react-redux";
import {
  addToSalesCart,
  removeFromSalesCart,
} from "../../Redux/Slices/SalesSlice";

const Container = styled.div`
  width: 100%;
  height: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 15.83px;
  background-color: ${({ theme }) => theme.white};
  padding: 15px;
  display: flex;
  justify-content: space-between;
  gap: 15px;

  ${media.tablet} {
    height: 181.89px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const MedicineImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 119.81px;
  height: 125.85px;
  border-radius: 15.83px;
  background: ${({ theme }) => theme.pink};

  img {
    width: 100%;
    height: 100%;
    border-radius: 15.83px;
  }
`;

const PriceTag = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;

  .naira {
    font-size: 7px;
    color: ${({ theme }) => theme.blue};
  }

  .price {
    font-size: 17px;
    font-weight: 500;
    line-height: 19.96px;
    color: ${({ theme }) => theme.black};
  }

  .per_card {
    font-size: 7px;
    font-weight: 400;
    line-height: 8.22px;
    color: #000000a3;
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
`;

const DrugName = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.09px;
  text-align: left;
  color: ${({ theme }) => theme.black};
`;

const DrugDescription = styled.div`
  font-size: 10px;
  font-weight: 400;
  /* line-height: 8.22px; */
  text-align: left;
  color: ${({ theme }) => theme.black};
  width: 80%;
`;

const StockAvailable = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 50px;
  width: 100%;

  .Desc {
    display: block;
    color: ${({ theme }) => theme.black};
    text-align: left;

    .title {
      font-size: 8px;
      font-weight: 400;
      line-height: 7.04px;
    }

    .value {
      font-size: 10px;
      font-weight: 500;
      line-height: 9.39px;
    }
  }
`;

const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.pink};
  width: 119.81px;
  height: 15.62px;
  border-radius: 15.83px;
  padding-left: 10px;
  padding-right: 10px;

  .minus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 50%;
    text-align: center;
    color: ${({ theme }) => theme.blue};
    cursor: pointer;
  }

  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.blue};
    border-radius: 50%;
    color: ${({ theme }) => theme.white};
    text-align: center;
    cursor: pointer;
  }

  .count {
    font-size: 10.55px;
    font-weight: 400;
    line-height: 12.39px;
    text-align: left;
    color: #000000a3;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;

const MedicineCard = ({ handleSell, handleBuy, medicine }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Container>
      <LeftContent>
        <MedicineImageContainer>
          <img
            src={medicine?.product?.imageUrl}
            alt={medicine?.product?.name}
          />
        </MedicineImageContainer>
        <PriceTag>
          <span className="naira">₦</span>
          <span className="price">{medicine?.price}</span>
          <span className="per_card">/ card</span>
        </PriceTag>
      </LeftContent>
      <RightContent>
        <DrugName>{medicine?.product?.name}</DrugName>
        <DrugDescription>{medicine?.product?.description}</DrugDescription>
        <StockAvailable>
          <div className="Desc">
            <p className="title">Batch</p>
            <p className="value">{medicine?.batch}</p>
          </div>
          <div className="Desc">
            <p className="title">Stock</p>
            <p
              className="value"
              style={{ color: medicine?.quantity === (0 || "0") && theme.red }}
            >
              {medicine?.quantity} Available
            </p>
          </div>
        </StockAvailable>
        <ButtonContainer>
          {handleBuy && (
            <RoundedButton
              btnText={"Buy"}
              bgColor={theme.green2}
              textColor={theme.white}
              handleClick={handleBuy}
            />
          )}
          {handleSell && (
            <RoundedButton
              btnText={"Sell"}
              bgColor={theme.red}
              textColor={theme.white}
              handleClick={handleSell}
            />
          )}
        </ButtonContainer>
        {medicine?.quantity !== (0 || "0") && (
          <Counter>
            <span
              className="minus"
              onClick={() => dispatch(removeFromSalesCart(medicine))}
            >
              -
            </span>
            <span className="count">{medicine?.count}</span>
            <span
              className="plus"
              onClick={() => dispatch(addToSalesCart(medicine))}
            >
              +
            </span>
          </Counter>
        )}
      </RightContent>
    </Container>
  );
};

export default MedicineCard;
