import React, { useState } from "react";
import {
  Counter,
  ItemContainer,
  ItemsLeftContainer,
  ItemsRightContainer,
} from "./styles";
import { toast } from "react-toastify";

const CartItem = ({
  itemImage,
  itemName,
  itemPrice,
  itemCount,
  handleIncrement,
  handleDecrement,
  handleRemoveItem,
  handleDynamicCountChange,
  productQuantity,
}) => {
  const [inputValue, setInputValue] = useState(itemCount);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (Number(value) <= Number(productQuantity)) {
      if (/^\d*$/.test(value)) {
        // Allow only numeric values
        setInputValue(value);
        handleDynamicCountChange(Number(value));
      }
    } else {
      toast.error(
        `Cannot add more that the available stock quantity: ${productQuantity}`
      );
    }
  };

  const handleBlur = () => {
    if (inputValue === "") {
      setInputValue(itemCount); // Reset to original count if input is empty
    }
  };

  return (
    <ItemContainer>
      <ItemsLeftContainer>
        <img src={itemImage} alt="drug" />
        <div className="drug_details">
          <p className="drug_name">{itemName}</p>
          <p style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <span className="naira">₦</span>
            <span className="price">{itemPrice}</span>
            <span className="per_card">/ card</span>
          </p>
        </div>
      </ItemsLeftContainer>
      <ItemsRightContainer>
        <img
          src="/assets/images/Generals/modal_close.svg"
          alt="close"
          className="cancel_button"
          onClick={handleRemoveItem}
        />
        <Counter>
          <button
            className="minus"
            onClick={() => {
              setInputValue(Number(inputValue) - 1);
              handleDecrement();
            }}
          >
            -
          </button>
          <input
            type="text"
            className="count"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          <button
            className="plus"
            onClick={() => {
              setInputValue(Number(inputValue) + 1);
              handleIncrement();
            }}
            disabled={Number(inputValue) === Number(productQuantity)}
          >
            +
          </button>
        </Counter>
      </ItemsRightContainer>
    </ItemContainer>
  );
};

export default CartItem;
