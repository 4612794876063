import React, { useEffect } from "react";
import CustomTable from "../../../components/Table/CustomTable";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import {
  clearCustomerState,
  selectCustomer,
  setCustomerToEdit,
  setCustomersPage,
  setCustomerspageSize,
} from "../../../Redux/Slices/CustomerSlice";
import { getCustomers } from "../../../Apis/CustomerManagement";
import { TableActions } from "../Online Order/styled";

export const columns = [
  // { label: "Customer ID", field: "customer_id" },
  { label: "Customer Name", field: "customer_name" },
  { label: "Customer Email", field: "customer_email" },
  { label: "Customer Address", field: "customer_address" },
  { label: "Phone Number", field: "phone_number" },
  { label: "Action", field: "action" },
];

const CustomerManagementTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    customers,
    gettingCustomers,
    customersPage,
    customerspageSize,
    customerToSearch,
  } = useSelector(selectCustomer);

  useEffect(() => {
    getCustomers(customersPage, customerspageSize, customerToSearch);
  }, [dispatch, customersPage, customerspageSize, customerToSearch]);

  const data = {
    columns,
    rows: customers
      ? customers?.items?.map((customer) => ({
          customer_id: customer.id,
          customer_name: customer.name,
          customer_email: customer.email,
          customer_address: customer.address,
          phone_number: customer.phoneNumber,
          store_point: customer.store,
          action: (
            <TableActions>
              <img
                src="/assets/images/Generals/edit.svg"
                alt="edit_icon"
                onClick={() => {
                  dispatch(setCurrentModal("edit_customer"));
                  dispatch(setCustomerToEdit(customer));
                }}
              />
              <img
                src="/assets/images/Generals/delete.svg"
                alt="delete_icon"
                onClick={() => {
                  dispatch(setCurrentModal("delete_customer"));
                  dispatch(setCustomerToEdit(customer));
                }}
              />
            </TableActions>
          ),
        }))
      : [],
  };

  return (
    <CustomTable
      data={data}
      noRecord={!gettingCustomers && customers?.totalItems === 0}
      title={"Customer Management"}
      showFilter={true}
      showHeader={true}
      showPagination={true}
      loading={gettingCustomers}
      headerButton={
        <FilledButton
          bgColor={theme.purple}
          btnText={"Add New Customer +"}
          textColor={theme.white}
          handleClick={() => {
            dispatch(clearCustomerState());
            dispatch(setCurrentModal("add_new_customer"));
          }}
        />
      }
      page={customersPage}
      limit={customerspageSize}
      handlePageChange={(value) => {
        dispatch(setCustomersPage(value));
      }}
      handlePageLimitChange={(value) => {
        dispatch(setCustomerspageSize(value));
      }}
      totalPages={customers?.totalPages}
    />
  );
};

export default CustomerManagementTable;
