import { toast } from "react-toastify";
import {
  setAddingProduct,
  setDeletingProduct,
  setGettingProducts,
  setProducts,
  setSearchedProducts,
  setSearchingProducts,
  setSelectedProductToEdit,
} from "../Redux/Slices/ProductSlice";
import { store } from "../Redux/app/store";
import api from "./baseUri";
import { setCurrentModal } from "../Redux/Slices/navSlice";

export const addProduct = async (productDetails) => {
  store.dispatch(setAddingProduct(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .post(`v1/pharmacies/${pharmacy?.id}/products`, productDetails, {
      headers: {
        "Content-Type": "multipart/form-data", // Let Axios handle the content-type for file uploads
      },
    })
    .then(async (res) => {
      const { productsPage, productsPageSize } = store.getState().products;
      toast.success(res.data.message);
      store.dispatch(setAddingProduct(false));
      await getProducts(productsPage, productsPageSize);
      store.dispatch(setCurrentModal("add_new_product_success"));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingProduct(false));
    });
};

export const fetchProductImages = async (q, u) => {
  try {
    const response = await api.get(
      `https://alpha.zamdahealth.com/v1/products/suggested-images?q=${q}&u=${u}`
    );
    return response.data; // Assumes the response structure includes a field 'images'
  } catch (error) {
    console.error("Error fetching product images:", error);
    return []; // Return an empty array on error
  }
};

export const editProduct = async (productDetails) => {
  store.dispatch(setAddingProduct(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .patch(`v1/pharmacies/${pharmacy?.id}/products`, productDetails, {
      headers: {
        "Content-Type": "multipart/form-data", // Let Axios handle the content-type for file uploads
      },
    })
    .then(async (res) => {
      const { productsPage, productsPageSize } = store.getState().products;
      toast.success(res.data.message);
      store.dispatch(setAddingProduct(false));
      await getProducts(productsPage, productsPageSize);
      store.dispatch(setCurrentModal("edit_product_success"));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingProduct(false));
    });
};

export const getProducts = async (page, pageSize) => {
  store.dispatch(setGettingProducts(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .get(
      `v1/pharmacies/${pharmacy?.id}/products?page=${page}&pageSize=${pageSize}`
    )
    .then((res) => {
      store.dispatch(setProducts(res.data)); // Assuming res.data.products holds the product data
      store.dispatch(setGettingProducts(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingProducts(false));
    });
};

export const searchProducts = async (page, searchParam, categoryId) => {
  store.dispatch(setSearchingProducts(true));

  // Build the query string dynamically
  const queryParams = new URLSearchParams();

  if (page !== null && page !== undefined) queryParams.append("page", page);
  if (searchParam) queryParams.append("search", searchParam);
  if (categoryId) queryParams.append("categoryId", categoryId);

  await api
    .get(`v1/products?${queryParams.toString()}`)
    .then((res) => {
      store.dispatch(setSearchedProducts(res.data)); // Assuming res.data.products holds the product data
      store.dispatch(setSearchingProducts(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setSearchingProducts(false));
    });
};

export const deleteProduct = async (productId) => {
  store.dispatch(setDeletingProduct(true));

  await api
    .delete(`v1/products/${productId}`)
    .then(async (res) => {
      const { productsPage, productsPageSize } = store.getState().products;
      toast.success(res.data.message);
      store.dispatch(setDeletingProduct(false));
      store.dispatch(setCurrentModal("delete_product_success"));
      await getProducts(productsPage, productsPageSize);
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setDeletingProduct(false));
    });
};

export const setProductToEditDetails = (product) => {
  store.dispatch(setSelectedProductToEdit(product));
};
