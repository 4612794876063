import React from "react";
import ColoredBgCard from "../../../components/Cards/ColoredBgCard";
import { useTheme } from "styled-components";
import { PulseLoader } from "react-spinners"; // Import the loader
import { TransactionSummaryContainer } from "./style";
import { useSelector } from "react-redux";
import { selectHome } from "../../../Redux/Slices/HomeSlice";
import { sumProperty } from "../../../helpers";

const TransactionSummary = () => {
  const { gettingTodaysTransactions, todaysTransaction } =
    useSelector(selectHome);
  const theme = useTheme();

  return (
    <TransactionSummaryContainer>
      <ColoredBgCard
        prop={"Total Sales"}
        cardBgColor={theme.cardBlue}
        value={
          gettingTodaysTransactions ? (
            <PulseLoader color="#00BFFF" size={8} />
          ) : (
            `₦ ${sumProperty(todaysTransaction?.items, "amount")}`
          )
        }
        iconBgColor={theme.bgBlue}
        icon={
          <img
            src="/assets/images/Dashboard/view_invoice.svg"
            alt="transaction"
          />
        }
        textColor={theme.grey5}
      />
      <ColoredBgCard
        prop={"Total Transactions"}
        cardBgColor={theme.cardRed}
        value={
          gettingTodaysTransactions ? (
            <PulseLoader color="#FF6347" size={8} />
          ) : (
            `${todaysTransaction?.totalItems || 0}`
          )
        }
        iconBgColor={theme.bgRed}
        icon={
          <img src="/assets/images/Dashboard/view_invoice.svg" alt="invoice" />
        }
        textColor={theme.grey5}
      />
      <ColoredBgCard
        prop={"Pending Transactions"}
        cardBgColor={theme.cardYellow}
        value={
          gettingTodaysTransactions ? (
            <PulseLoader color="#FFD700" size={8} />
          ) : (
            `₦ ${
              todaysTransaction?.items?.filter(
                (x) => x.status === "pending_payment"
            ).length
            }`
          )
        }
        iconBgColor={theme.bgYellow}
        icon={
          <img src="/assets/images/Dashboard/view_invoice.svg" alt="products" />
        }
        textColor={theme.grey5}
      />
      <ColoredBgCard
        prop={"Successful Transactions"}
        cardBgColor={theme.cardGrey}
        value={
          gettingTodaysTransactions ? (
            <PulseLoader color="#00BFFF" size={8} />
          ) : (
            `₦ ${
              todaysTransaction?.items?.filter(
                (x) => x.status !== "pending_payment"
              ).length
            }`
          )
        }
        iconBgColor={theme.bgGreen}
        icon={<img src="/assets/images/Dashboard/naira.svg" alt="naira" />}
        textColor={theme.grey5}
      />
    </TransactionSummaryContainer>
  );
};

export default TransactionSummary;
