import React, { useEffect, useState } from "react";
import {
  LeftContainer,
  MedicinesContainer,
  RightContainer,
  SalesContainer,
  SectionContainer,
  SectionHeader,
  SectionTitle,
  WaitingListCards,
} from "./styles";
import { Link } from "react-router-dom";
import WaitingListCard from "../../../components/Cards/WaitingListCard";
import MedicinesTab from "../../../components/Tabs/MedicinesTab";
import MedicineCard from "../../../components/Cards/MedicineCard";
import SalesInvoice from "./SalesInvoice";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";
import { useDispatch, useSelector } from "react-redux";
import { selectSales, setSalesCart } from "../../../Redux/Slices/SalesSlice";
import {
  getStockStateUpdate,
  selectStock,
} from "../../../Redux/Slices/StockSlice";
import { selectCategory } from "../../../Redux/Slices/CategorySlice";
import CustomPagination from "../../../components/Table/CustomPagination";
import AllCategoryModal from "../../../components/Tabs/AllCategoryModal";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { searchStocks } from "../../../Apis/StockManagement";

const Sales = () => {
  const { salesCart, waitingLists, printingInvoice } = useSelector(selectSales);
  const { searchedStocks, searchStockPage, StockToSearch, StockByCategoryId } =
    useSelector(selectStock);
  const { categories } = useSelector(selectCategory);
  const [medCategories, setMedCategories] = useState();
  const dispatch = useDispatch();

  const handlePage = (page) => {
    dispatch(getStockStateUpdate({ name: "searchStockPage", value: page }));
  };

  const handleCategorySelect = (value) => {
    dispatch(getStockStateUpdate({ name: "StockByCategoryId", value }));
    dispatch(setCurrentModal(""));
  };

  useEffect(() => {
    searchStocks(searchStockPage, StockToSearch, StockByCategoryId);
  }, [searchStockPage, StockToSearch, StockByCategoryId, printingInvoice]);

  useEffect(() => {
    const filterCategories = categories?.items?.map((x) => {
      return {
        id: x?.id,
        name: x?.levelTwoName,
        value: x?.levelTwoCode,
      };
    });
    setMedCategories([{ id: 0, name: "All" }].concat(filterCategories));
  }, [categories]);

  return (
    <SalesContainer>
      <LeftContainer flex={salesCart?.cartItems?.length === 0 ? 1 : 0.7}>
        <SectionContainer>
          <SectionTitle>Waiting List</SectionTitle>
          <WaitingListCards>
            {waitingLists?.map((item, index) => (
              <WaitingListCard
                customerName={item?.patientName}
                id={index + 1}
                indexNumber={item?.patientId}
                itemsCount={item?.cartItems?.length}
                key={index + 1}
                handleClick={() => dispatch(setSalesCart(item))}
              />
            ))}
          </WaitingListCards>
        </SectionContainer>
        <SectionContainer>
          <SectionHeader>
            <SectionTitle>Medicines</SectionTitle>
            <Link onClick={() => dispatch(setCurrentModal("all_categories"))}>
              See all categories
            </Link>
          </SectionHeader>
          <MedicinesTab
            tabList={medCategories?.slice(0, 4)}
            handleClick={handleCategorySelect}
          />
          <MedicinesContainer
            grid={
              salesCart?.cartItems?.length > 0
                ? "repeat(3, 1fr)"
                : "repeat(4, 1fr)"
            }
          >
            {searchedStocks?.items?.map((item, index) => (
              <MedicineCard key={index + 1} medicine={item} />
            ))}
          </MedicinesContainer>
        </SectionContainer>

        <CustomPagination
          currentPage={searchedStocks?.currentPage}
          pageSize={searchedStocks?.pageSize}
          totalItems={searchedStocks?.totalItems}
          totalPages={searchedStocks?.totalPages}
          updatePage={handlePage}
        />
      </LeftContainer>
      <RightContainer
        flex={salesCart?.cartItems?.length > 0 ? 0.3 : 0}
        display={salesCart?.cartItems?.length > 0 ? "flex" : "none"}
      >
        <SalesInvoice />
      </RightContainer>
      <SuccessMessageModal
        id="printed_invoice_modal"
        message="Take Your Sales Invoice"
      />
      <CanceledMessageModal
        id="canceled_invoice_modal"
        message="Invoice Canceled"
      />
      <AllCategoryModal handleClick={handleCategorySelect} />
    </SalesContainer>
  );
};

export default Sales;
