import React, { useState } from "react";
import {
  LeftContent,
  NotificationContainer,
  NotificationList,
  NotificationTitle,
  NotificationsContent,
  RightContent,
} from "./styles";
import NotificationCard from "../../../components/Cards/NotificationCard";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import ManageNotification from "./ManageNotification";

const Notification = () => {
  const theme = useTheme();
  const [showSetting, setShowSetting] = useState(false);

  return (
    <NotificationContainer>
      <NotificationTitle>Notifications</NotificationTitle>
      <NotificationsContent>
        <LeftContent>
          <img
            src="/assets/images/Notification/image1.svg"
            alt="notification_banner"
          />
        </LeftContent>
        <RightContent>
          <FilledButton
            bgColor={theme.purple}
            btnText={"Manage Notifications"}
            textColor={theme.white}
            handleClick={() => setShowSetting(true)}
          />
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <NotificationList>
              <NotificationCard
                text={"New Order"}
                link={"/notification/new-orders"}
              />
              <NotificationCard
                text={"Expiring"}
                link={"/notification/expiring"}
              />
              <NotificationCard
                text={"Support"}
                link={"/notification/support-messages"}
              />
              <NotificationCard
                text={"Expired"}
                link={"/notification/expired"}
              />
              <NotificationCard
                text={"Out of Stock"}
                link={"/notification/out-of-stock"}
              />
            </NotificationList>
          </div>
        </RightContent>
      </NotificationsContent>
      {showSetting && <ManageNotification setShowSetting={setShowSetting} />}
    </NotificationContainer>
  );
};

export default Notification;
