import React, { useRef } from "react";
import {
  CartContainer,
  DetailsInvoiceContainer,
  DetailsTable,
  DetailsTableData,
  DetailsTableHead,
  DetailsTableRow,
  FooterButtonContainer,
  FooterItem,
  SalesInvoiceContainer,
  SalesInvoiceFooter,
  SalesInvoiceHeaderContainer,
} from "./styles";
import CartItem from "./CartItem";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { useTheme } from "styled-components";
import {
  addToSalesCart,
  clearSalesCart,
  removeFromSalesCart,
  removeItemFromSalesCart,
  selectSales,
  setPrintingInvoice,
  updateCountDynamically,
} from "../../../Redux/Slices/SalesSlice";
import MinimizeButtonComponent from "../../../components/Reusables/MinizeButton";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import MinimizeInvoiceModal from "./modal/MinimiseInvoiceModal";
import PatientNameModal from "./modal/PatientNameModal";

const SalesInvoice = () => {
  const {
    salesCart,
    summary,
    vat,
    discount,
    total,
    waitingLists,
    printingInvoice,
  } = useSelector(selectSales);

  const dispatch = useDispatch();
  const theme = useTheme();
  const salesInvoiceRef = useRef();

  const handleCancel = () => {
    dispatch(setCurrentModal("canceled_invoice_modal"));
    dispatch(setPrintingInvoice(false));
    dispatch(clearSalesCart());
  };

  const handleMinimize = () => {
    const exists = waitingLists.some(
      (entry) => entry.patientName === salesCart?.patientName
    );
    if (exists) {
      dispatch(clearSalesCart());
    } else if (waitingLists?.length < 3) {
      dispatch(setCurrentModal("minimize_patient"));
    } else {
      toast.error("You have reached your minimization limit");
    }
  };

  const handlePrint = useReactToPrint({
    content: () => {
      return salesInvoiceRef.current;
    },
    documentTitle: `${salesCart?.patientName} Sales Invoice`,
    onAfterPrint: () => toast.success("Invoice printed successfully!"),
    onPrintError: () => toast.error("Failed to print invoice."),
  });

  const handlePrintInvoice = async (e) => {
    e.preventDefault();

    if (!salesCart?.patientName) {
      dispatch(setCurrentModal("patient_name"));
      return;
    }
  };

  return (
    <SalesInvoiceContainer ref={salesInvoiceRef}>
      <MinimizeButtonComponent handleClick={handleMinimize} />
      <SalesInvoiceHeaderContainer>
        <p className="title">Sales Invoice</p>
        <p className="id">
          #1 <sup>1237659</sup>
        </p>
      </SalesInvoiceHeaderContainer>
      <DetailsInvoiceContainer>
        <p className="title">
          Details Invoice:{" "}
          <span className="total_cart_items">
            {salesCart?.cartItems?.length}
          </span>
        </p>
        <DetailsTable>
          <DetailsTableRow>
            <DetailsTableHead>Name</DetailsTableHead>
            <DetailsTableHead>Qty</DetailsTableHead>
            <DetailsTableHead>Amount(₦)</DetailsTableHead>
            <DetailsTableHead>Total(₦)</DetailsTableHead>
          </DetailsTableRow>
          {salesCart?.cartItems?.map((item, index) => (
            <DetailsTableRow key={index + 1}>
              <DetailsTableData>{item?.product?.name}</DetailsTableData>
              <DetailsTableData>x{item?.count}</DetailsTableData>
              <DetailsTableData>{item?.price}</DetailsTableData>
              <DetailsTableData>{item?.price * item?.count}</DetailsTableData>
            </DetailsTableRow>
          ))}
        </DetailsTable>
      </DetailsInvoiceContainer>

      <CartContainer>
        {salesCart?.cartItems?.map((item, index) => (
          <CartItem
            itemImage={item?.product?.imageUrl}
            itemName={item?.product?.name}
            itemCount={item?.count}
            itemPrice={item?.price}
            key={index + 1}
            handleDecrement={() => dispatch(removeFromSalesCart(item))}
            handleIncrement={() => dispatch(addToSalesCart(item))}
            handleRemoveItem={() => dispatch(removeItemFromSalesCart(item))}
            handleDynamicCountChange={(value) =>
              dispatch(updateCountDynamically({ ...item, amount: value }))
            }
            productQuantity={item?.quantity}
          />
        ))}
      </CartContainer>
      <SalesInvoiceFooter>
        <FooterItem>
          <span className="summary">Summary</span>
          <span className="value">
            <span>₦</span> {summary?.toFixed(2)}
          </span>
        </FooterItem>
        <FooterItem>
          <span className="title">7% VAT</span>
          <span className="value">
            <span>₦</span> {vat?.toFixed(2)}
          </span>
        </FooterItem>
        <FooterItem>
          <span className="title">Discount %</span>
          <span className="value">
            <span>₦</span> {discount?.toFixed(2)}
          </span>
        </FooterItem>
        <FooterItem>
          <span className="summary">Total</span>
          <span className="value">
            <span>₦</span> {total?.toFixed(2)}
          </span>
        </FooterItem>
      </SalesInvoiceFooter>
      <FooterButtonContainer>
        <FilledButton
          btnText={"Print Bill"}
          bgColor={theme.purple}
          textColor={"#fff"}
          handleClick={handlePrintInvoice}
          loading={printingInvoice}
        />
        <FilledButton
          btnText={"Cancel"}
          bgColor={theme.red}
          textColor={"#fff"}
          handleClick={handleCancel}
        />
      </FooterButtonContainer>
      <MinimizeInvoiceModal />
      <PatientNameModal handlePrint={handlePrint} />
    </SalesInvoiceContainer>
  );
};

export default SalesInvoice;
