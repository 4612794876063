import React from "react";
import { RecordsSummaryCardsContainer } from "./style";
import WhiteBgCard from "../../../components/Cards/WhiteBgCard";
import { PulseLoader } from "react-spinners";

const RecordsSummaryCards = ({ data, loading }) => {
  return (
    <RecordsSummaryCardsContainer>
      <WhiteBgCard
        icon={
          <img src="/assets/images/Dashboard/suppliers.svg" alt="suppliers" />
        }
        prop={"Suppliers"}
        value={loading ? <PulseLoader color="#666" size={8} /> : data.suppliers}
        link={"/supplier-management"}
      />
      <WhiteBgCard
        icon={
          <img src="/assets/images/Dashboard/invoices_2.svg" alt="invoices" />
        }
        prop={"Invoices"}
        value={
          loading ? <PulseLoader color="#666" size={8} /> : data.todaysInvoices
        }
        link={"inventory"}
      />
      <WhiteBgCard
        icon={
          <img src="/assets/images/Dashboard/sales_2.svg" alt="record_sales" />
        }
        prop={"Current Month Sales"}
        value={
          loading ? (
            <PulseLoader color="#666" size={8} />
          ) : (
            `₦ ${data.currentMonthSales}`
          )
        }
        link="/transactions"
      />
      <WhiteBgCard
        icon={
          <img
            src="/assets/images/Dashboard/record.svg"
            alt="last-3-months-sales"
          />
        }
        prop={"Last 3 Months Sales"}
        value={
          loading ? (
            <PulseLoader color="#666" size={8} />
          ) : (
            `₦ ${data.lastThreeMonthsSales}`
          )
        }
        link="/transactions"
      />
      <WhiteBgCard
        icon={
          <img src="/assets/images/Dashboard/sales.svg" alt="month_sales" />
        }
        prop={"Last 6 Months Record Sales"}
        value={
          loading ? (
            <PulseLoader color="#666" size={8} />
          ) : (
            `₦ ${data.sixMonthsRecordSales || 0}`
          )
        }
        link="/transactions"
      />
      <WhiteBgCard
        icon={
          <img src="/assets/images/Dashboard/stock.svg" alt="out_of_stocks" />
        }
        prop={"Out of Stock"}
        value={
          loading ? <PulseLoader color="#666" size={8} /> : data.outOfStocks
        }
        link={"/notification/out-of-stock"}
      />
      <WhiteBgCard
        icon={<img src="/assets/images/Dashboard/product.svg" alt="products" />}
        prop={"Total Products"}
        value={
          loading ? <PulseLoader color="#666" size={8} /> : data.totalProducts
        }
        link="/product-management"
      />
      <WhiteBgCard
        icon={
          <img src="/assets/images/Dashboard/revenue.svg" alt="year_revenue" />
        }
        prop={"Current Year Revenue"}
        value={
          loading ? (
            <PulseLoader color="#666" size={8} />
          ) : (
            `₦ ${data.currentYearRevenue}`
          )
        }
        link="/analytics"
      />
      <WhiteBgCard
        icon={<img src="/assets/images/Dashboard/stores.svg" alt="stores" />}
        prop={"Stores"}
        value={loading ? <PulseLoader color="#666" size={8} /> : data.stores}
        link="/stores"
      />
    </RecordsSummaryCardsContainer>
  );
};

export default RecordsSummaryCards;
