import React from "react";
import CustomTable from "../../../components/Table/CustomTable";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { TableActions } from "../Online Order/styled";
import {
  clearSupplier,
  getSupplierStateUpdate,
  selectSupplier,
  setSupplierToEdit,
} from "../../../Redux/Slices/SupplierSlice";
import moment from "moment";

export const columns = [
  { label: "Name", field: "name" },
  { label: "Email", field: "email" },
  { label: "Phone Number", field: "phone_number" },
  { label: "Address", field: "address" },
  { label: "Date Added", field: "date_added" },
  { label: "Action", field: "action" },
];

const SupplierManagementTable = () => {
  const { suppliers, gettingSuppliers } = useSelector(selectSupplier);
  const theme = useTheme();
  const dispatch = useDispatch();

  const rows = suppliers?.items?.map((item) => {
    return {
      name: item?.name,
      email: item?.email,
      phone_number: item?.phoneNumber,
      address: item?.address,
      company: item?.company,
      date_added: moment(item?.createdAt).format("Do MMM YYYY"),
      action: (
        <TableActions>
          <img
            src="/assets/images/Generals/edit.svg"
            alt="edit_icon"
            onClick={() => {
              dispatch(
                getSupplierStateUpdate({ name: "supplierId", value: item?.id })
              );
              dispatch(setSupplierToEdit(item));
              dispatch(setCurrentModal("edit_supplier"));
            }}
          />
          <img
            src="/assets/images/Generals/delete.svg"
            alt="delete_icon"
            onClick={() => {
              dispatch(
                getSupplierStateUpdate({ name: "supplierId", value: item?.id })
              );
              dispatch(setCurrentModal("delete_supplier"));
            }}
          />
        </TableActions>
      ),
    };
  });

  const data = {
    columns,
    rows,
  };

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Supplier Management"}
      showFilter={true}
      showHeader={true}
      showPagination={true}
      filter={
        <FilledButton
          bgColor={theme.purple}
          btnText={"Add Supplier +"}
          textColor={theme.white}
          handleClick={() => {
            dispatch(clearSupplier());
            dispatch(setCurrentModal("add_new_supplier"));
          }}
        />
      }
      loading={gettingSuppliers}
    />
  );
};

export default SupplierManagementTable;
