/* eslint-disable react/require-default-props */
import { useRef } from "react";
import {
  PaginationContainer,
  Table2,
  TableBody,
  TableContainer2,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  TableTitle,
} from "./styles";
import "rsuite/dist/rsuite.css";
import { useTheme } from "styled-components";
import CustomScaleLoader from "../Loaders/ScaleLoader";

const CustomTable = ({
  data,
  title,
  loading,
  tableId,
  tableRef,
  searchOptions,
  noRecord,
  filter,
  showPagination,
  showHeader,
  titleColor,
  headerButton,
  handlePageChange,
  handlePageLimitChange,
  page,
  limit,
  totalPages,
}) => {
  const tableScroll = useRef();
  const theme = useTheme();

  return (
    <TableContainer2 ref={tableScroll}>
      <TableTitle titleColor={titleColor}>{title}</TableTitle>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "30px",
          width: "100%",
        }}
      >
        {filter && filter}
        {headerButton && headerButton}
      </div>
      {searchOptions}
      {!loading ? (
        <div style={{ width: "100%", overflowX: "auto" }}>
          {!noRecord ? (
            <Table2 id={tableId} ref={tableRef}>
              {showHeader && (
                <TableHeader>
                  <TableRow>
                    {data?.columns?.map((column) => (
                      <TableHead key={column}>{column?.label}</TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
              )}
              <TableBody>
                {data?.rows?.map((row) => (
                  <TableRow key={row}>
                    {data?.columns?.map((column) => (
                      <TableData key={column}>
                        {row[`${column.field}`]}
                      </TableData>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table2>
          ) : (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "semibold",
                color: "grey",
              }}
            >
              No Record Available
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomScaleLoader size={10} color={theme.blue} />
        </div>
      )}
      {showPagination && (
        <div style={{ padding: 20 }}>
          <PaginationContainer
            prev
            next
            //   first
            //   last
            ellipsis
            boundaryLinks
            maxButtons={2}
            size="xs"
            layout={["total", "-", "limit", "pager"]}
            total={totalPages || 1}
            limitOptions={[10, 20, 30]}
            limit={limit}
            activePage={page}
            onChangePage={handlePageChange}
            onChangeLimit={handlePageLimitChange}
          />
        </div>
      )}
    </TableContainer2>
  );
};

export default CustomTable;
