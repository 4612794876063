import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Label = styled.div`
  font-size: 11.54px;
  font-weight: 500;
  line-height: 15.67px;
  text-align: left;
  text-transform: capitalize;

  .required {
    color: ${({ theme }) => theme.red};
    font-size: 12px;
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;

  input {
    width: 100%;
    padding: 6px 8px;
    border: 1.65px solid #d9d9d9;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    height: 38px;
  }
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  z-index: 1000;

  li {
    padding: 8px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

const SearchInputDropdown = ({
  label,
  placeholder,
  items,
  handleSelect,
  customStyle,
  required,
  name,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleInputChange = (e) => {
    const value = e?.target?.value;
    setInputValue(value);
    if (handleSelect) handleSelect(name, value);

    // Filter dropdown items
    const matches = items?.filter((item) =>
      item?.value
        ? item?.label?.toLowerCase()?.includes(value?.toLowerCase())
        : item?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setFilteredItems(matches);

    // Show dropdown if matches are available
    setShowDropdown(matches?.length > 0);
  };

  const handleItemClick = (item) => {
    setInputValue(item?.label || item);
    setShowDropdown(false);
    if (handleSelect) handleSelect(name, item?.value || item);
  };

  const handleBlur = () => {
    // If no match, retain the input value
    if (!items?.value?.includes(inputValue)) {
      setInputValue(inputValue);
    }
    setShowDropdown(false);
  };

  return (
    <Container style={customStyle}>
      <Label>
        {label} <span className="required">{required && "*"}</span>
      </Label>
      <InputContainer>
        <input
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onFocus={() => setShowDropdown(true)}
        />
        {showDropdown && (
          <Dropdown>
            {filteredItems?.map((item, index) => (
              <li key={index} onMouseDown={() => handleItemClick(item)}>
                {item?.label || item}
              </li>
            ))}
          </Dropdown>
        )}
      </InputContainer>
    </Container>
  );
};

export default SearchInputDropdown;
