import React from "react";
import TransactionTable from "../Home/TodaysTransactionTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomeStateUpdate,
  selectHome,
} from "../../../Redux/Slices/HomeSlice";
import TransactionSummary from "./TransactionsSummary";
import moment from "moment";

const Transactions = () => {
  const { startDate, endDate } = useSelector(selectHome);
  const dispatch = useDispatch();

  const handleDateRangeChange = (value) => {
    if (value?.length > 0) {
      dispatch(
        getHomeStateUpdate({
          name: "startDate",
          value: moment(value[0]).format("YYYY/MM/DD"),
        })
      );
      dispatch(
        getHomeStateUpdate({
          name: "endDate",
          value: moment(value[1]).format("YYYY/MM/DD"),
        })
      );
    } else {
      dispatch(
        getHomeStateUpdate({
          name: "startDate",
          value: moment().format("YYYY/MM/DD"),
        })
      );
      dispatch(
        getHomeStateUpdate({
          name: "endDate",
          value: moment().format("YYYY/MM/DD"),
        })
      );
    }
  };
  return (
    <>
      <TransactionSummary />
      <TransactionTable
        transactionTitle={"Transactions"}
        startDate={startDate}
        endDate={endDate}
        type={"all_transactions"}
        handleDateChange={handleDateRangeChange}
      />
    </>
  );
};

export default Transactions;
