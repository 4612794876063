import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProduct } from "../../../../Redux/Slices/ProductSlice";

// Styled component for page content
const PageWrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  min-width: 600px;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
  color: #333;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      font-size: 24px;
      font-weight: bold;
      color: #211f7e;
    }

    button {
      background-color: #211f7e;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 10px 15px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #211f7e;
      }
    }
  }

  .details {
    margin-bottom: 20px;

    .field {
      margin-bottom: 10px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;

      strong {
        font-weight: bold;
        color: #555;
      }

      span {
        font-size: 14px;
        color: #333;
      }
    }
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    img {
      max-width: 100%;
      max-height: 300px;
      border: 1px solid #ddd;
      border-radius: 8px;
      object-fit: cover;
    }
  }
`;

const ViewProductDetailsPage = () => {
  const navigate = useNavigate();
  const { selectedProductToEdit } = useSelector(selectProduct);

  // Handle navigation back
  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  if (!selectedProductToEdit) {
    return (
      <PageWrapper>
        <div className="header">
          <h1>Product Details</h1>
          <button onClick={handleBack}>Back</button>
        </div>
        <p>No product details available.</p>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <div className="header">
        <h1>Product Details</h1>
        <button onClick={handleBack}>Back</button>
      </div>
      <div className="details">
        {Object.entries(selectedProductToEdit).map(([key, value]) => (
          <div key={key} className="field">
            <strong>{key.replace(/_/g, " ").toUpperCase()}:</strong>{" "}
            <span>{value}</span>
          </div>
        ))}
      </div>
      <div className="image-container">
        <img
          src={selectedProductToEdit?.imageUrl}
          alt={selectedProductToEdit?.name}
        />
      </div>
    </PageWrapper>
  );
};

export default ViewProductDetailsPage;
