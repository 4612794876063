import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { BiArrowBack, BiPrinter } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceToView,
  getInvoiceToViewItems,
} from "../../../../Apis/Inventory";
import { selectInventory } from "../../../../Redux/Slices/InventorySlice";
import { useNavigate, useParams } from "react-router-dom";
import { selectProduct } from "../../../../Redux/Slices/ProductSlice";
import { getProducts } from "../../../../Apis/product";
import { setModalStack } from "../../../../Redux/Slices/navSlice";
import EditInvoiceModal from "./EditInvoiceModal";
import { selectSupplier } from "../../../../Redux/Slices/SupplierSlice";

const InvoiceWrapper = styled.div`
  background-color: #f9f9f9;
  padding: 30px;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  border-radius: 8px;
  font-family: "Arial", sans-serif;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;

  h1 {
    font-size: 24px;
    font-weight: bold;
    text-align: right;
    margin: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    .logo {
      font-size: 40px;
      font-weight: bold;
      color: #000;
    }

    .invoice-details {
      text-align: right;
      font-size: 14px;

      p {
        margin: 5px 0;
      }
    }
  }

  .billing {
    margin-bottom: 20px;

    h2 {
      font-size: 16px;
      margin-bottom: 5px;
    }

    p {
      margin: 2px 0;
      font-size: 14px;
    }
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    th,
    td {
      padding: 10px;
      text-align: left;
      font-size: 14px;
    }

    th {
      border-bottom: 1px solid #ddd;
    }

    tr:not(:last-child) td {
      border-bottom: 1px solid #ddd;
    }

    .total-row td {
      font-weight: bold;
    }

    .total-amount {
      color: #000;
    }
  }

  .footer {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
  }

  @media print {
    body * {
      visibility: hidden;
    }
    .InvoiceWrapper,
    .InvoiceWrapper * {
      visibility: visible;
    }
    .InvoiceWrapper {
      position: absolute;
      left: 0;
      top: 0;
    }
    .print-button {
      display: none;
    }
  }
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background: #fff;
  box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.25);
  padding: 4px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const ViewInvoicePage = () => {
  const { selectedInvoiceToView, selectedInvoiceToViewItems } =
    useSelector(selectInventory);
  const { productsPage, productsPageSize, products } =
    useSelector(selectProduct);
  const { suppliers } = useSelector(selectSupplier);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    id && getInvoiceToView(id);
    id && getInvoiceToViewItems(id);
  }, [id]);

  useEffect(() => {
    getProducts(productsPage, productsPageSize);
  }, [productsPage, productsPageSize]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="flex flex-col">
      <BackButton onClick={() => navigate("/inventory")}>
        <BiArrowBack />
        <span>Back</span>
      </BackButton>
      <InvoiceWrapper className="InvoiceWrapper">
        <div className="header">
          <div className="logo">&</div>
          <div className="invoice-details">
            <h1>INVOICE</h1>
            <p>Invoice No. {selectedInvoiceToView?.ref}</p>
            <p>
              {new Date(
                selectedInvoiceToView?.invoiceDate
              )?.toLocaleDateString()}
            </p>
          </div>
        </div>
        <div className="billing">
          {/* <h2>BILLED TO:</h2> */}
          <h2>
            Supplier:{" "}
            {
              suppliers?.items?.find(
                (x) => x?.id === selectedInvoiceToView?.supplierId
              )?.name
            }
          </h2>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {selectedInvoiceToViewItems?.items?.map((item, index) => (
              <tr key={item?.id}>
                <td>{index + 1}</td>
                <td>
                  {products?.items?.filter((x) => x.id === item?.productId)[0]
                    ?.name || "N/A"}
                </td>
                <td>{item?.quantity}</td>
                <td>{`NGN ${item?.costPrice}`}</td>
                <td>{`NGN ${item?.quantity * item?.costPrice}`}</td>
              </tr>
            ))}
            <tr className="total-row">
              <td colSpan="3">Subtotal</td>
              <td>{`$${selectedInvoiceToView?.amount}`}</td>
            </tr>
            <tr className="total-row">
              <td colSpan="3" className="total-amount">
                Total
              </td>
              <td className="total-amount">{`$${selectedInvoiceToView?.amount}`}</td>
            </tr>
          </tbody>
        </table>
        <div className="footer">Thank you!</div>
        <Footer>
          <button onClick={handlePrint}>
            <BiPrinter size={20} />
          </button>
          <div
            onClick={() => {
              dispatch(setModalStack("edit_invoice"));
            }}
            style={{ color: theme.purple, fontSize: "16px", cursor: "pointer" }}
          >
            Add Items
          </div>
        </Footer>
      </InvoiceWrapper>
      <EditInvoiceModal invoice={selectedInvoiceToView} />
    </div>
  );
};

export default ViewInvoicePage;
