import { BiReceipt } from "react-icons/bi";
import { lightTheme } from "../../../theme";

export const sidebarNavs = [
  {
    id: 1,
    name: "Dashboard",
    icon: (
      <img src="/assets/images/NavIcons/dashboard.svg" alt="dashboard-icon" />
    ),
    link: "/",
  },
  {
    id: 2,
    name: "Sales",
    icon: <img src="/assets/images/NavIcons/sales.svg" alt="sales-icon" />,
    link: "/sales",
  },
  {
    id: 3,
    name: "Customer Management",
    icon: (
      <img
        src="/assets/images/NavIcons/customer-management.svg"
        alt="customer-management-icon"
      />
    ),
    link: "/customer-management",
  },
  // {
  //   id: 4,
  //   name: "Prescription Management",
  //   icon: (
  //     <img
  //       src="/assets/images/NavIcons/prescription-management.svg"
  //       alt="prescription-management-icon"
  //     />
  //   ),
  //   link: "/prescription-management",
  // },
  {
    id: 14,
    name: "Product Management",
    icon: (
      <img
        src="/assets/images/NavIcons/product-management.svg"
        alt="product-management-icon"
      />
    ),
    link: "/product-management",
  },
  {
    id: 17,
    name: "Stock Management",
    icon: <BiReceipt color={lightTheme.purple} />,
    link: "/stock-management",
  },
  {
    id: 15,
    name: "Supplier Management",
    icon: (
      <img
        src="/assets/images/NavIcons/supplier-management.svg"
        alt="supplier-management-icon"
      />
    ),
    link: "/supplier-management",
  },
  {
    id: 5,
    name: "Online Order/Pickup",
    icon: (
      <img
        src="/assets/images/NavIcons/online-pickup.svg"
        alt="online-order-icon"
      />
    ),
    link: "/online-order",
  },
  {
    id: 6,
    name: "Marketplace",
    icon: (
      <img
        src="/assets/images/NavIcons/marketplace.svg"
        alt="marketplace-icon"
      />
    ),
    link: "/marketplace",
  },
  {
    id: 7,
    name: "Inventory",
    icon: (
      <img src="/assets/images/NavIcons/inventory.svg" alt="inventory-icon" />
    ),
    link: "/inventory",
  },
  {
    id: 8,
    name: "Analytics",
    icon: (
      <img src="/assets/images/NavIcons/analytics.svg" alt="analytics-icon" />
    ),
    link: "/analytics",
  },
  {
    id: 16,
    name: "Transactions",
    icon: <BiReceipt color={lightTheme.purple} />,
    link: "/transactions",
  },

  {
    id: 9,
    name: "Employee Management",
    icon: (
      <img
        src="/assets/images/NavIcons/employee-management.svg"
        alt="employee-management-icon"
      />
    ),
    link: "/employee-management",
  },
  {
    id: 10,
    name: "Branch Management",
    icon: (
      <img
        src="/assets/images/NavIcons/branch-management.svg"
        alt="branch-management-icon"
      />
    ),
    link: "/branch-management",
  },
  {
    id: 11,
    name: "Health Management",
    icon: (
      <img
        src="/assets/images/NavIcons/health-management.svg"
        alt="health-management-icon"
      />
    ),
    link: "/health-management",
  },
  {
    id: 12,
    name: "Customer Support",
    icon: (
      <img
        src="/assets/images/NavIcons/customer-support.svg"
        alt="customer-support-icon"
      />
    ),
    link: "/customer-support",
  },
  {
    id: 13,
    name: "Notification",
    icon: (
      <img
        src="/assets/images/NavIcons/notificatioin.svg"
        alt="notification-icon"
      />
    ),
    link: "/notification",
  },
];
