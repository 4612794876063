import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalId: "",
  showSidebar: false,
  closeSidebar: false,
  progress: 0,
  modalStack: [],
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setModalStack: (state, action) => {
      if (action.payload) {
        state.modalStack.push(action.payload); // Add modal ID to stack
      } else {
        state.modalStack.pop(); // Remove the topmost modal ID
      }
    },
    closeModalStack: (state, action) => {
      state.modalStack = state.modalStack.filter(
        (modal) => modal !== action.payload
      );
    },
    closeAllModalStack: (state) => {
      state.modalStack = [];
    },
    setCurrentModal: (state, action) => {
      state.modalId = action.payload;
    },
    toggleSidebar: (state) => {
      state.showSidebar = !state.showSidebar;
    },
    setLoadingProgress: (state, action) => {
      state.progress = action.payload;
    },
    CloseSidebarOnSelect: (state) => {
      state.showSidebar = false;
    },
  },
});



export const {
  setCurrentModal,
  toggleSidebar,
  CloseSidebarOnSelect,
  setLoadingProgress,
  setModalStack,
  closeModalStack,
  closeAllModalStack,
} = navSlice.actions;

export const selectNav = (state) => state.nav;

export default navSlice.reducer;
