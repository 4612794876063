import React from "react";
import InvoiceTable from "./InvoiceTable";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import { useDispatch } from "react-redux";
import AddNewInvoiceModal from "./modals/AddNewInvoiceModal";
import AddNewSupplierModal from "../SupplierManagment/modals/AddNewSupplierModal";
import AddProductModal from "../ProductManagement/modals/AddProductModal";

const Inventory = () => {
  const dispatch = useDispatch();

  return (
    <>
      <InvoiceTable />
      <AddNewInvoiceModal />
      <AddNewSupplierModal />
      <AddProductModal />
      <SuccessMessageModal id="add_new_supplier_success" message={"Done"} />
      <SuccessMessageModal id={"add_new_product_success"} message={"Done"} />
      <SuccessMessageModal id="adding_invoice_item_success" message={"Done"} />
      <ConfirmModal
        confirmOkText={"Delete"}
        handleOkClick={() =>
          dispatch(setCurrentModal("delete_invoice_success"))
        }
        id={"delete_invoice"}
        message={
          "Confirm you want to delete this invoice, Note that this invoice will be permanently deleted from the database"
        }
      />
      <CanceledMessageModal
        id={"delete_invoice_success"}
        message={"Deleted Successfully"}
      />
      <SuccessMessageModal id={"add_invoice_success"} message={"Done"} />
    </>
  );
};

export default Inventory;
