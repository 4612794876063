import React, { useEffect } from "react";
import ProductTable from "./ProductTable";
import AddProductModal from "./modals/AddProductModal";
import EditProductModal from "./modals/EditProductModal";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { useSelector } from "react-redux";
import { getCategories } from "../../../Apis/Category";
import { selectCategory } from "../../../Redux/Slices/CategorySlice";
import { selectProduct } from "../../../Redux/Slices/ProductSlice";
import { deleteProduct } from "../../../Apis/product";

const ProductManagement = () => {
  const { categoriesPage, categoriesPageSize } = useSelector(selectCategory);
  const { deletingProduct, productId } = useSelector(selectProduct);

  useEffect(() => {
    getCategories(categoriesPage, categoriesPageSize);
  }, [categoriesPage, categoriesPageSize]);

  return (
    <>
      <ProductTable />
      <AddProductModal />
      <EditProductModal />
      <SuccessMessageModal id={"add_new_product_success"} message={"Done"} />
      <SuccessMessageModal id={"edit_product_success"} message={"Done"} />
      <CanceledMessageModal
        id={"delete_product_success"}
        message="Deleted Successfully"
      />
      <ConfirmModal
        message="By deleting this product all content and data on this product will be deleted confirm you want to delete this branch."
        confirmOkText={"Delete"}
        id={"delete_product"}
        handleOkClick={() => deleteProduct(productId)}
        deleting={deletingProduct}
      />
    </>
  );
};

export default ProductManagement;
