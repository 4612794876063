import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingStocks: false,
  addingStock: false,
  deletingStock: false,

  // Stock pagination
  Stocks: [], // List of Stocks
  StocksPageSize: 20, // Number of Stocks per page
  StocksPage: 1, // Current page of Stocks
  searchedStocks: [],
  searchingStocks: false,
  searchStockPage: 1,
  StockToSearch: "",
  StockByCategoryId: null,

  // Stock details
  name: "",
  imageUrl: null,
  currencyId: "",
  description: "",
  invoiceNumber: "",
  batch: "",
  costPrice: 0,
  sellingPrice: 0,
  qty: 0,
  supplier: null,
  category: null,
  mtfDate: null,
  expDate: null,
  selectedStock: null, // Selected Stock for Editing
};

export const StockSlice = createSlice({
  name: "Stock",
  initialState,
  reducers: {
    getStockStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingStocks
    setGettingStocks: (state, action) => {
      state.gettingStocks = action.payload;
    },

    // Reducer to update searchingStocks
    setSearchingStocks: (state, action) => {
      state.searchingStocks = action.payload;
    },

    // Reducer to update addingStock
    setAddingStock: (state, action) => {
      state.addingStock = action.payload;
    },

    // Reducer to update deletingStock
    setDeletingStock: (state, action) => {
      state.deletingStock = action.payload;
    },

    // Reducer to update Stocks
    setStocks: (state, action) => {
      state.Stocks = action.payload;
    },

    // Reducer to update searched Stocks
    setSearchedStocks: (state, action) => {
      state.searchedStocks = action.payload;
    },

    // Reducer to update StockspageSize
    setStockspageSize: (state, action) => {
      state.StocksPageSize = action.payload;
    },

    // Reducer to update StocksPage
    setStocksPage: (state, action) => {
      state.StocksPage = action.payload;
    },

    // Reducer to update StockBarCode
    setStockBarCode: (state, action) => {
      state.StockBarCode = action.payload;
    },

    // Reducer to update StockCategory
    setStockCategory: (state, action) => {
      state.StockCategory = action.payload;
    },

    // Reducer to update StockName
    setStockName: (state, action) => {
      state.StockName = action.payload;
    },

    // Reducer to update StockExpiryDate
    setStockExpiryDate: (state, action) => {
      state.StockExpiryDate = action.payload;
    },

    // Reducer to update StockPrice
    setStockPrice: (state, action) => {
      state.StockPrice = action.payload;
    },

    // Reducer to update StockInStock
    setStockInStock: (state, action) => {
      state.StockInStock = action.payload;
    },

    // Reducer to update store
    setStore: (state, action) => {
      state.store = action.payload;
    },

    // Reducer to update status
    setStatus: (state, action) => {
      state.status = action.payload;
    },

    // Reducer to update minQuantity
    setMinQuantity: (state, action) => {
      state.minQuantity = action.payload;
    },

    // Reducer to update selectedStock
    setSelectedStock: (state, action) => {
      state.selectedStock = action.payload;
      state.name = action.payload?.name;
      state.currencyId = action.payload?.currencyId;
      state.description = action.payload?.description;
      state.batch = action.payload?.batch;
      state.costPrice = action.payload.costPrice;
      state.sellingPrice = action.payload.sellingPrice;
      state.qty = action.payload.quantity;
      state.supplier = action.payload.supplierId;
      state.category = action.payload.categoryId;
      state.mtfDate = action.payload.dateOfManufacture;
      state.expDate = action.payload.dateOfExpiration;
    },
  },
});

export const {
  getStockStateUpdate,
  setGettingStocks,
  setAddingStock,
  setDeletingStock,
  setStocks,
  setStockspageSize,
  setStocksPage,
  setStockBarCode,
  setStockCategory,
  setStockName,
  setStockExpiryDate,
  setStockPrice,
  setStockInStock,
  setStore,
  setStatus,
  setMinQuantity,
  setSelectedStock,
  setSearchedStocks,
  setSearchingStocks,
} = StockSlice.actions;

export const selectStock = (state) => state.stocks;

export default StockSlice.reducer;
