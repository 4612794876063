import React, { useEffect, useState } from "react";
import CustomModal from "../Modals/CustomModal";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectCategory } from "../../Redux/Slices/CategorySlice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
  padding: 20px;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.white};
  color: #0000008f;
  border-radius: 10.55px;
  padding: 0 20px;
  height: 21px;
  cursor: pointer;
  width: auto;

  &.active {
    background-color: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
  }
`;

const AllCategoryModal = ({ handleClick }) => {
  const { categories } = useSelector(selectCategory);
  const [medCategories, setMedCategories] = useState();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const filterCategories = categories?.items?.map((x) => {
      return {
        id: x?.id,
        name: x?.levelTwoName,
      };
    });
    setMedCategories([{ id: 0, name: "All" }].concat(filterCategories));
  }, [categories]);

  return (
    <CustomModal
      id="all_categories"
      content={
        <Container>
          <ModalTitle>All Categories</ModalTitle>
          <TabsContainer>
            {medCategories?.map((tab, index) => (
              <Tab
                key={index + tab?.id}
                className={tab?.id === currentTab && "active"}
                onClick={() => {
                  setCurrentTab(tab?.id);
                  handleClick(tab?.id);
                }}
              >
                {tab?.name}
              </Tab>
            ))}
          </TabsContainer>
        </Container>
      }
    />
  );
};

export default AllCategoryModal;
