import React, { useEffect } from "react";
import CustomTable from "../../../components/Table/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHome,
  setTransactionsPage,
  setTransactionspageSize,
} from "../../../Redux/Slices/HomeSlice";
import { getTodaysTransactions } from "../../../Apis/Home";
import moment from "moment";
import { DateRangePicker } from "rsuite";
// import ResponsiveDateRangePickers from "../../../components/Inputs/DateRangePicker";

export const columns = [
  { label: "Order ID", field: "order_id" },
  { label: "Customer", field: "customer" },
  // { label: "Payment", field: "payment" },
  { label: "Amount (₦)", field: "amount" },
  // { label: "Attendant", field: "attendant" },
  { label: "Status", field: "status" },
  { label: "Date", field: "date" },
];

const TodaysTransactionTable = ({
  transactionTitle,
  startDate,
  endDate,
  type,
  handleDateChange,
}) => {
  const {
    transactionPage,
    transactionspageSize,
    gettingTodaysTransactions,
    todaysTransaction,
  } = useSelector(selectHome);
  const dispatch = useDispatch();

  const rows = todaysTransaction?.items?.map((transaction, index) => {
    return {
      order_id: index + 1,
      customer: transaction?.customerName,
      payment: transaction?.payment_status,
      amount: `${transaction?.currency?.symbol + " " + transaction?.amount}`,
      attendant: transaction?.name,
      status: transaction?.status,
      date: moment(transaction?.createdAt).format("Do MMM YYYY"),
    };
  });

  const data = {
    columns,
    rows,
  };

  useEffect(() => {
    getTodaysTransactions(
      transactionPage,
      transactionspageSize,
      startDate,
      endDate
    );
  }, [transactionPage, transactionspageSize, startDate, endDate]);

  return (
    <CustomTable
      data={data}
      noRecord={
        !gettingTodaysTransactions && todaysTransaction?.totalItems === 0
      }
      title={transactionTitle}
      showFilter={true}
      showHeader={true}
      showPagination={true}
      loading={gettingTodaysTransactions}
      page={transactionPage}
      limit={transactionspageSize}
      handlePageChange={(value) => {
        dispatch(setTransactionsPage(value));
      }}
      handlePageLimitChange={(value) => {
        dispatch(setTransactionspageSize(value));
      }}
      totalPages={todaysTransaction?.totalPages}
      filter={
        type === "all_transactions" && (
          <DateRangePicker
            onChange={handleDateChange}
            placeholder={["Start Date--", "--End Date"]}
          />
        )
      }
    />
  );
};

export default TodaysTransactionTable;
