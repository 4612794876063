export const productFormTypes = [
  {
    label: "Tablet",
    value: "Tablet",
  },
  {
    label: "Liquid",
    value: "Liquid",
  },
  {
    label: "Ointment",
    value: "Ointment",
  },
  {
    label: "Tube",
    value: "Tube",
  },
  {
    label: "Eye Drops",
    value: "Eye Drops",
  },
  {
    label: "Spray",
    value: "Spray",
  },
  {
    label: "Injection",
    value: "Injection",
  },
];

export const unitType = [
  { label: "Sachet/Card", value: "sachet/card" },
  { label: "Bottle/Cup", value: "bottle/Cup" },
  { label: "Pack/Box", value: "pack/box" },
  { label: "Tube", value: "tube" },
  { label: "Ampoule", value: "ampoule" },
];
