import { toast } from "react-toastify";
import {
  setBusinessSummary,
  setGettingBusinessSummary,
  setGettingTodaysTransactions,
  setTodaysTransactions,
  setTransactionsPage,
  setTransactionspageSize,
} from "../Redux/Slices/HomeSlice";
import { store } from "../Redux/app/store";
import api from "./baseUri";

export const getBusinessSummary = async () => {
  store.dispatch(setGettingBusinessSummary(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .get(`v1/pharmacies/${pharmacy?.id}/dashboard`)
    .then((res) => {
      store.dispatch(setBusinessSummary(res.data));
      store.dispatch(setGettingBusinessSummary(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingBusinessSummary(false));
    });
};

export const getTodaysTransactions = async (
  page,
  pageSize,
  startDate,
  endDate
) => {
  store.dispatch(setGettingTodaysTransactions(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .get(
      `v1/pharmacies/${pharmacy?.id}/sales?page=${page || 1}&pageSize=${
        pageSize || 20
      }&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      // Assuming you have an action to set today's transactions
      store.dispatch(setTodaysTransactions(res.data)); // Define this action in your slice
      store.dispatch(setTransactionsPage(page));
      store.dispatch(setTransactionspageSize(pageSize));
      store.dispatch(setGettingTodaysTransactions(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingTodaysTransactions(false));
    });
};
