import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/Table/CustomTable";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { setModalStack } from "../../../Redux/Slices/navSlice";
import { TableActions } from "../Online Order/styled";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  selectInventory,
  setInvoicePage,
  setInvoicepageSize,
} from "../../../Redux/Slices/InventorySlice";
import { getInvoices } from "../../../Apis/Inventory";
import EditInvoiceModal from "./modals/EditInvoiceModal";
import { useNavigate } from "react-router-dom";
import { selectSupplier } from "../../../Redux/Slices/SupplierSlice";

export const columns = [
  { label: "Invoice Id", field: "invoice_id" },
  // { label: "Batch Number", field: "batch_number" },
  { label: "Invoice Date", field: "invoice_date" },
  { label: "Total Amount", field: "total_amount" },
  { label: "Reference", field: "ref" },
  { label: "Supplier", field: "supplier" },
  { label: "Status", field: "status" },
  { label: "Date Added", field: "date_added" },
  { label: "Action", field: "action" },
];

const InvoiceTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [invoiceToView, setInvoiceToView] = useState();
  const { invoicePage, invoicepageSize, invoices, gettingInvoices } =
    useSelector(selectInventory);
  const { suppliers } = useSelector(selectSupplier);

  const rows = invoices?.items?.map((item, index) => {
    return {
      invoice_id: index + 1,
      batch_number: item?.batch,
      date_added: moment(item?.createdAt).format("Do MMM YYYY"),
      total_amount: "NGN " + item?.amount,
      invoice_date: moment(item?.invoiceDate).format("Do MMM YYYY"),
      ref: item?.ref,
      status: item?.status,
      supplier: suppliers?.items?.find((x) => x?.id === item?.supplierId)?.name,
      action: (
        <TableActions>
          <div
            onClick={() => {
              setInvoiceToView(item);
              navigate(`/inventory/${item?.id}`);
            }}
            style={{ color: theme.purple, fontSize: "16px", cursor: "pointer" }}
          >
            View Details
          </div>
          <div
            onClick={() => {
              dispatch(setModalStack("edit_invoice"));
              setInvoiceToView(item);
            }}
            style={{ color: theme.purple, fontSize: "16px", cursor: "pointer" }}
          >
            Add Items
          </div>
        </TableActions>
      ),
    };
  });

  const data = {
    columns,
    rows,
  };

  useEffect(() => {
    getInvoices(invoicePage, invoicepageSize);
  }, [invoicePage, invoicepageSize]);

  return (
    <>
      <EditInvoiceModal invoice={invoiceToView} />
      <CustomTable
        loading={gettingInvoices}
        data={data}
        noRecord={!gettingInvoices && invoices?.totalItems === 0}
        title={"Invoice"}
        showFilter={true}
        showHeader={true}
        showPagination={true}
        headerButton={
          <FilledButton
            bgColor={theme.purple}
            btnText={"Add New Invoice +"}
            textColor={theme.white}
            handleClick={() => dispatch(setModalStack("add_invoice"))}
          />
        }
        filter={<p>.</p>}
        page={invoicePage}
        limit={invoicepageSize}
        handlePageChange={(value) => {
          dispatch(setInvoicePage(value));
        }}
        handlePageLimitChange={(value) => {
          dispatch(setInvoicepageSize(value));
        }}
        totalPages={invoices?.totalPages}
      />
    </>
  );
};

export default InvoiceTable;
