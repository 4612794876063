import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNav, setCurrentModal } from "../../Redux/Slices/navSlice";
import styled from "styled-components";
import { media } from "../../Screens";

const CustomModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 100000000;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
`;

const ContentContainer = styled.div`
  position: relative;
  background: #fff;
  width: 90%;
  height: auto;
  padding: 20px;
  min-height: 359px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 1000000000000;

  ${media.phone} {
    width: auto;
    min-width: 389px;
    max-width: 60%;
  }
`;

const Close = styled.img`
  align-self: flex-end;
  width: 8px;
  cursor: pointer;
`;

const CustomModal = ({ content, id }) => {
  const { modalId } = useSelector(selectNav);
  const dispatch = useDispatch();

  return (
    <>
      {id === modalId && (
        <CustomModalContainer
          id="modal_parent"
          onClick={(e) => {
            e.target.id === "modal_parent" && dispatch(setCurrentModal(""));
          }}
        >
          <ContentContainer>
            <Close
              src="/assets/images/Generals/modal_close.svg"
              onClick={() => dispatch(setCurrentModal(""))}
            />
            {content}
          </ContentContainer>
        </CustomModalContainer>
      )}
    </>
  );
};

export default CustomModal;
