import { toast } from "react-toastify";
import { store } from "../Redux/app/store";
import api from "./baseUri";
import { clearSalesCart, setPrintingInvoice } from "../Redux/Slices/SalesSlice";
import { setCurrentModal } from "../Redux/Slices/navSlice";

export const printInvoice = async (invoiceDetails) => {
  store.dispatch(setPrintingInvoice(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .post(`v1/pharmacies/${pharmacy?.id}/sales`, invoiceDetails)
    .then((res) => {
      toast.success("Successful");
      store.dispatch(setPrintingInvoice(false));
      store.dispatch(setCurrentModal("printed_invoice_modal"));
      store.dispatch(clearSalesCart());
      store.dispatch(setCurrentModal(""));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setPrintingInvoice(false));
    });
};
