import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 363px;
  height: 34px;
  border-radius: 11px;
  display: flex;
  gap: 15px;
  padding: 10px 15px;
  align-items: center;
  background-color: ${({ theme }) => theme.purpleLight};
`;

const SearchIcon = styled.img``;

const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
`;

const SearchInput = ({ placeholder, value, defaultValue, handleChange, name }) => {
  return (
    <Container>
      <SearchIcon
        src="/assets/images/Generals/search_icon.svg"
        alt="search_icon"
      />
      <Input
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        name={name}
      />
    </Container>
  );
};

export default SearchInput;
