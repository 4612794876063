import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 70px;
  flex-wrap: wrap;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.white};
  color: #0000008f;
  border-radius: 10.55px;
  padding: 0 20px;
  min-height: 21px;
  height: auto;
  cursor: pointer;
  width: auto;

  &.active {
    background-color: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
  }
`;

const MedicinesTab = ({ tabList, handleClick }) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Container>
      {tabList?.map((tab, index) => (
        <Tab
          key={index + tab?.id}
          className={tab?.id === currentTab && "active"}
          onClick={() => {
            setCurrentTab(tab?.id);
            handleClick(tab?.id);
          }}
        >
          {tab?.name}
        </Tab>
      ))}
    </Container>
  );
};

export default MedicinesTab;
