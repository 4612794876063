/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import SelectOptions from "../../../../components/Inputs/SelectOptions";
import {
  getProductStateUpdate,
  selectProduct,
} from "../../../../Redux/Slices/ProductSlice";
import { addProduct, fetchProductImages } from "../../../../Apis/product";
import { selectCategory } from "../../../../Redux/Slices/CategorySlice";
import { mapToLabelValueArray } from "../../../../helpers";
import { productFormTypes, unitType } from "./util";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const ImageSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ImageOption = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border: ${({ selected, theme }) =>
    selected ? `2px solid ${theme.purple}` : "2px solid transparent"};
  border-radius: 8px;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: last baseline;
  gap: 10px;
  width: 100%;
`;

const searchWith = [
  {
    label: "Google",
    value: "gc",
  },
  { label: "Bing", value: "bing" },
];

const AddProductModal = () => {
  const theme = useTheme();
  const {
    product_name,
    brand_name,
    generic_name,
    strength,
    // description,
    form,
    category,
    manufacturer,
    barcode,
    unit_type,
    addingProduct,
    atcClassificationId,
  } = useSelector(selectProduct);
  const dispatch = useDispatch();
  const { productCategories } = useSelector(selectCategory);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchEngine, setSearchEngine] = useState("gc");
  const [searchingImage, setSearchingImage] = useState(false);
  const { categories } = useSelector(selectCategory);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getProductStateUpdate({ name, value }));
  };

  const handleImageSearch = async () => {
    setSearchingImage(true);
    const fetchedImages = await fetchProductImages(
      brand_name + " " + form + " " + product_name + " " + strength,
      searchEngine
    );
    setSearchingImage(false);
    setImages(fetchedImages);
  };

  const handleImageSelection = (image) => {
    setSelectedImage(image);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    addProduct({
      name: product_name,
      brandName: brand_name,
      genericName: generic_name,
      strength,
      // description,
      form,
      categoryId: Number(category),
      manufacturer,
      barcode,
      unitSize: unit_type,
      // unit: unit_type,
      atcClassificationId: Number(atcClassificationId),
      imageUrl: selectedImage?.link,
    });
  };

  return (
    <CustomModal
      id="add_new_product"
      content={
        <Container>
          <ModalTitle>Add Product</ModalTitle>
          <FlexContainer>
            <TextInput
              label={"Product Name"}
              placeholder={"Enter Product Name"}
              name={"product_name"}
              value={product_name}
              handleChange={handleChange}
            />
            <TextInput
              label={"Brand Name"}
              placeholder={"Enter Brand Name"}
              name={"brand_name"}
              value={brand_name}
              handleChange={handleChange}
            />{" "}
            <TextInput
              label={"Generic Name"}
              placeholder={"Enter Generic Name"}
              name={"generic_name"}
              value={generic_name}
              handleChange={handleChange}
            />
          </FlexContainer>
          <FlexContainer>
            <TextInput
              label={"Strength"}
              placeholder={"Enter Strength"}
              name={"strength"}
              value={strength}
              handleChange={handleChange}
            />
            <SelectOptions
              label={"Form"}
              placeholder={"Select Form"}
              name={"form"}
              value={form}
              handleSelect={handleChange}
              options={productFormTypes}
            />
          </FlexContainer>
          <FlexContainer>
            <TextInput
              label={"Manufacturer"}
              placeholder={"Enter Manufacturer"}
              name={"manufacturer"}
              value={manufacturer}
              handleChange={handleChange}
            />
            <TextInput
              label={"Barcode"}
              placeholder={"Enter Barcode"}
              name={"barcode"}
              value={barcode}
              handleChange={handleChange}
            />
          </FlexContainer>
          <FlexContainer>
            <SelectOptions
              placeholder={"Select Category"}
              label={"Category"}
              name={"category"}
              handleSelect={handleChange}
              value={category}
              options={mapToLabelValueArray(
                productCategories?.items,
                "name",
                "id"
              )}
            />
            <SelectOptions
              placeholder={"Select ATC Category"}
              label={"ATC Category"}
              name={"atcClassificationId"}
              handleSelect={handleChange}
              value={atcClassificationId}
              options={mapToLabelValueArray(
                categories?.items,
                "levelTwoName",
                "id"
              )}
            />
            <SelectOptions
              label={"Unit Size"}
              placeholder={"Enter Unit Type"}
              name={"unit_type"}
              value={unit_type}
              handleSelect={handleChange}
              options={unitType}
            />
          </FlexContainer>
          {/* <FlexContainer>
            <TextInput
              label={"Unit Size"}
              placeholder={"Enter Unit Size"}
              name={"unit_size"}
              value={unit_size}
              handleChange={handleChange}
            />
          </FlexContainer> */}
          {/* <TextArea
            label={"Description"}
            placeholder={"Enter Product Description"}
            name={"description"}
            value={description}
            handleChange={handleChange}
          /> */}
          <ImageSelectionContainer>
            <ModalTitle>Search and Select Product Image</ModalTitle>
            <SearchBar>
              {/* <TextInput
                placeholder={"Search for product images..."}
                value={searchQuery}
                handleChange={(e) => setSearchQuery(e.target.value)}
                label="Search Product Name"
              /> */}
              <SelectOptions
                placeholder={"Select Search engine"}
                label={"Search Engine"}
                name={"searchEngine"}
                handleSelect={(e) => setSearchEngine(e.target.value)}
                value={searchEngine}
                options={searchWith}
              />
              <FilledButton
                bgColor={theme.purple}
                btnText={"Search"}
                handleClick={handleImageSearch}
                textColor={theme.white}
                loading={searchingImage}
                disabled={searchingImage}
              />
            </SearchBar>
            <ImageList>
              {images.map((img, index) => (
                <ImageOption
                  key={index}
                  src={img?.link}
                  selected={selectedImage === img}
                  onClick={() => handleImageSelection(img)}
                  alt={`Product image ${index + 1}`}
                />
              ))}
            </ImageList>
          </ImageSelectionContainer>
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleSubmit}
              textColor={theme.white}
              loading={addingProduct}
              disabled={
                !product_name ||
                !brand_name ||
                !generic_name ||
                !strength ||
                // !description ||
                !form ||
                !category ||
                !manufacturer ||
                !barcode ||
                !unit_type ||
                !selectedImage ||
                addingProduct
              }
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default AddProductModal;
