import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { HomeContainer } from "./style";
import SummaryCards from "./SummaryCards";
import RecordsSummaryCards from "./RecordsSummaryCards";
import TodaysTransactionTable from "./TodaysTransactionTable";
import { selectHome } from "../../../Redux/Slices/HomeSlice";
import { getBusinessSummary } from "../../../Apis/Home";
import moment from "moment";

const Home = () => {
  const { gettingBusinessSummary, businessSummary } = useSelector(selectHome);

  useEffect(() => {
    getBusinessSummary();
  }, []);

  return (
    <HomeContainer>
      <SummaryCards data={businessSummary} loading={gettingBusinessSummary} />
      <RecordsSummaryCards
        data={businessSummary}
        loading={gettingBusinessSummary}
      />
      <TodaysTransactionTable
        transactionTitle={"Todays Transactions"}
        startDate={moment().format("YYYY/MM/DD")}
        endDate={moment().format("YYYY/MM/DD")}
      />
    </HomeContainer>
  );
};

export default Home;
