import React from "react";
import SearchInput from "../../../components/Inputs/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import { getStockStateUpdate, selectStock } from "../../../Redux/Slices/StockSlice";

const SalesSearchBar = () => {
  const { StockToSearch } = useSelector(selectStock);
  const dispatch = useDispatch()

    const handleChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target;
      dispatch(getStockStateUpdate({ name, value }));
    };


  return (
    <SearchInput
      placeholder={"Search Medicine"}
      value={StockToSearch}
      handleChange={handleChange}
      name={"StockToSearch"}
    />
  );
};

export default SalesSearchBar;
