import { toast } from "react-toastify";
import {
  setAddingInvoice,
  setAddingInvoiceItem,
  setGettingInvoices,
  setInvoicePage,
  setInvoicepageSize,
  setInvoices,
  setSelectedInvoiceToView,
  setSelectedInvoiceToViewItems,
} from "../Redux/Slices/InventorySlice";
import { store } from "../Redux/app/store";
import api from "./baseUri";
import { closeAllModalStack, setCurrentModal } from "../Redux/Slices/navSlice";

export const getInvoices = async (page, pageSize) => {
  store.dispatch(setGettingInvoices(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .get(
      `v1/pharmacies/${pharmacy?.id}/invoices?page=${page}&pageSize=${pageSize}`
    )
    .then((res) => {
      store.dispatch(setInvoices(res?.data));
      store.dispatch(setInvoicePage(page));
      store.dispatch(setInvoicepageSize(pageSize));
      store.dispatch(setGettingInvoices(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingInvoices(false));
    });
};

export const addInvoice = async (invoiceData) => {
  store.dispatch(setAddingInvoice(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;
  const { invoicePage, invoicepageSize } = store?.getState()?.inventory;

  await api
    .post(`v1/pharmacies/${pharmacy?.id}/invoices`, invoiceData)
    .then(async (res) => {
      toast.success(res.data.message);
      store.dispatch(setAddingInvoice(false));
      store.dispatch(setCurrentModal("add_invoice_success"));
      store.dispatch(closeAllModalStack());
      await getInvoices(invoicePage, invoicepageSize);
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingInvoice(false));
    });
};

export const addItemToInvoice = async (invoiceDetails) => {
  store.dispatch(setAddingInvoiceItem(true));
  const { invoicePage, invoicepageSize } = store?.getState()?.inventory;

  await api
    .post(`v1/pharmacy-invoices/${invoiceDetails?.id}/items`, {
      items: invoiceDetails?.productList,
    })
    .then(async (res) => {
      toast.success(res.data.message);
      store.dispatch(setAddingInvoiceItem(false));
      store.dispatch(closeAllModalStack());
      store.dispatch(setCurrentModal("adding_invoice_item_success"));
      await getInvoices(invoicePage, invoicepageSize);
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingInvoiceItem(false));
    });
};

export const getInvoiceToView = async (invoiceId) => {
  // store.dispatch(setGettingInvoices(true));
  await api
    .get(`v1/pharmacy-invoices/${invoiceId}`)
    .then((res) => {
      store.dispatch(setSelectedInvoiceToView(res.data));
      // store.dispatch(setGettingInvoices(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      // store.dispatch(setGettingInvoices(false));
    });
};

export const getInvoiceToViewItems = async (invoiceId) => {
  store.dispatch(setGettingInvoices(true));
  await api
    .get(`v1/pharmacy-invoices/${invoiceId}/items`)
    .then((res) => {
      store.dispatch(setSelectedInvoiceToViewItems(res.data));
      store.dispatch(setGettingInvoices(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingInvoices(false));
    });
};
