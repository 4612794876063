import React, { useEffect } from "react";
import AddStockModal from "./modals/AddStockModal";
import EditStockModal from "./modals/EditStockModal";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { getCategories } from "../../../Apis/Category";
import { selectCategory } from "../../../Redux/Slices/CategorySlice";
import StockTable from "./Table";

const StockManagement = () => {
  const dispatch = useDispatch();
  const { categoriesPage, categoriesPageSize } = useSelector(selectCategory);

  useEffect(() => {
    getCategories(categoriesPage, categoriesPageSize);
  }, [categoriesPage, categoriesPageSize]);

  return (
    <>
      <StockTable />
      <AddStockModal />
      <EditStockModal />
      <SuccessMessageModal id={"add_new_Stock_success"} message={"Done"} />
      <SuccessMessageModal id={"edit_Stock_success"} message={"Done"} />
      <CanceledMessageModal
        id={"delete_Stock_success"}
        message="Deleted Successfully"
      />
      <ConfirmModal
        message="By deleting this Stock all content and data on this Stock will be deleted confirm you want to delete this branch."
        confirmOkText={"Delete"}
        id={"delete_Stock"}
        handleOkClick={() => dispatch(setCurrentModal("delete_Stock_success"))}
      />
    </>
  );
};

export default StockManagement;
